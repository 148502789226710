import React from "react";
import styles from "./permissionCard.module.scss";
import { Button } from "@ornament-ui/kit/Button";
import { Typography } from "@ornament-ui/kit/Typography";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";
import { ModalType } from "../ConnectionModal/ConnectionModal";
import { store } from "../../../../store/store";
import { BioInstanceDto, ConnectionDataCandidate } from "../../../../types";
import { Tag } from "@ornament-ui/kit/__inner__/esm/components/Tag";
import { Grid, GridItem } from "@ornament-ui/kit/__inner__/esm/components/Grid";
import SuccessTitle from "../SuccessTitle";
import { TickIcon } from "@ornament-ui/icons";
import { CloseIcon } from "@ornament-ui/icons";

type PermissionCardProps = {
  data: BioInstanceDto;
  setConnectionDataCandidate: React.Dispatch<
    React.SetStateAction<ConnectionDataCandidate>
  >;
};

const PermissionCard: React.FC<PermissionCardProps> = ({
  data,
  setConnectionDataCandidate,
}) => {
  const isMobile = useIsMobile();

  const getTags = () => {
    if (data.rejected || data.enabled) {
      return (
        <SuccessTitle
          text={data.enabled ? "Биометрия подключена" : "Запрос отклонен"}
          icon={
            data.enabled ? (
              <TickIcon size="m" color="#04C300" />
            ) : (
              <CloseIcon size="m" color="#FB002F" />
            )
          }
          color={data.enabled ? "#04C300" : "#FB002F"}
        />
      );
    }
  };

  return (
    <li className={styles.cardItem}>
      <Grid
        cols={isMobile ? 1 : 12}
        className={styles.cardItem__grid}
        gap={"s"}
      >
        {/* Icon */}
        <GridItem
          colStart={1}
          rowStart={isMobile ? 1 : 1}
          row={isMobile ? 1 : 3}
          col={isMobile ? 1 : 2}
          className={styles.cardItem__grid_item}
        >
          <div className={styles.imageContainer}>
            <img
              src={`data:image/png;base64,${data.photoBase64}`}
              alt="лого компании"
              style={{ maxWidth: isMobile ? "90px" : "100px", width: "100%" }}
            />
          </div>
        </GridItem>

        {/* Название */}
        <GridItem
          rowStart={isMobile ? 2 : 1}
          col={isMobile ? 1 : 6}
          colStart={isMobile ? 1 : 3}
          className={styles.cardItem__grid_item}
        >
          {isMobile && getTags()}

          <Typography
            variant={isMobile ? "text-l_strong" : "text-xl_strong"}
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              marginBottom: "12px",
              fontFamily: "Rubrik New",
              marginTop: "10px"
            }}
          >
            {data.name}
          </Typography>
          <Tag
            style={{ width: "fit-content", marginBottom: "16px" }}
            as="div"
            label={data.useFor ?? "Face2"}
            size="s"
            variant="info"
          />
          <Typography
            style={{ marginBottom: isMobile ? "24px" : "0" }}
            variant={isMobile ? "text-s" : "text-m"}
          >
            {data.address}
          </Typography>
        </GridItem>

        {/* Кнопка */}
        <GridItem
          colStart={isMobile ? 1 : 9}
          rowStart={isMobile ? 3 : 1}
          col={isMobile ? 1 : 4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: isMobile ? "start" : "end",
            width: "fit-content",
            marginLeft: isMobile ? "" : "auto",
          }}
          className={styles.cardItem__grid_item}
        >
          {!isMobile && getTags()}
          {data.enabled ? (
            <Button
              variant={"ghost"}
              size={isMobile ? "xs" : "s"}
              fullWidth={true}
              className={styles.btn}
              style={{
                fontSize: "16px",
                background: "#893EEC26",
                borderRadius: "8px",
                marginTop: "10px"
              }}
              onClick={() =>
                setConnectionDataCandidate({
                  ...data,
                  type: ModalType.disconnect,
                  isOpen: true,
                })
              }
            >
              Отключить биометрию
            </Button>
          ) : (
            <Button
              variant={"ghost"}
              disabled={!store.profile?.isAgreement}
              size={isMobile ? "xs" : "s"}
              className={styles.btn}
              style={{
                  fontSize: "16px",
                  background: !store.profile?.isAgreement ? "#CCCCCC" : "#893EEC",
                  color: !store.profile?.isAgreement ? "#7D7D7D" : "white",
                  borderRadius: "8px",
                  marginTop: "10px",
                  cursor: !store.profile?.isAgreement ? "not-allowed" : "pointer",
                  opacity: !store.profile?.isAgreement ? 0.6 : 1,
              }}
              onClick={() =>
                setConnectionDataCandidate({
                  ...data,
                  type: ModalType.connect,
                  isOpen: true,
                })
              }
            >
              Подключить биометрию
            </Button>
          )}
        </GridItem>
      </Grid>
    </li>
  );
};

export default PermissionCard;
