import {Container} from "@ornament-ui/kit/Container";
import {Typography} from "@ornament-ui/kit/Typography";
import styles from "./header.module.scss";
import {useIsMobile} from "../../utils/hooks/useIsMobile";
import cn from "classnames";
import {store} from "../../../store/store";
import {observer} from "mobx-react";
import {ExitIcon} from "@ornament-ui/icons";
import NavBar from "./NavBar";
import {Dialog, DialogBody, DialogFooter, DialogHeader, DialogTitle,} from "@ornament-ui/kit/Dialog";
import {Button} from "@ornament-ui/kit/__inner__/esm/components/Button";
import {useState} from "react";

const Header = observer(() => {
  const isMobile = useIsMobile();
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);


  function getFirstLetter(str: string) {
    try {
      if (str && typeof str === "string") {
        return str[0]?.toUpperCase();
      } else {
        return "";
      }
    } catch (e) {
      return "";
    }
  }
  if (!store.profile) {
    return null;
  }

  return (
    <div className={styles.bgWhite}>
      <Container style={{ padding: 0 }} size={"l"}>
        <header className={styles.header}>
          <div
            className={cn({
              [styles.mobileMenuContainer]: isMobile,
            })}
          >
            <div
              onClick={() => {
                store.setPage(store.getPages[0]);
              }}
              className={styles.logo}
            >
              <img src={"./face2logo.svg"} alt={"логотип face 2"} />
            </div>
          </div>

          <span className={styles.fio}>
            <Typography>
              {store.profile?.lastName && store.profile?.lastName}{" "}
              {store.profile?.firstName &&
                getFirstLetter(store.profile.firstName)}
              {"."}
              {store.profile?.middleName &&
                getFirstLetter(store.profile.middleName)}
              {"."}
            </Typography>

            <ExitIcon
              onClick={() => setOpenConfirm(true)}
              style={{ paddingTop: "2px", cursor: "pointer" }}
            />
          </span>
          <NavBar />
          <footer className={styles.footer}>
            <Typography
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open("https://face2.ru/", "_blank");
              }}
              color={"action"}
            >
              Сайт Face2
            </Typography>
            {/* <Typography color="disabled">АО «Социальная карта» 2024</Typography> */}
          </footer>
        </header>
      </Container>

      <Dialog
        onClose={() => setOpenConfirm(false)}
        size="l"
        variant="medium"
        open={openConfirm}
      >
        <DialogHeader>
          <DialogTitle>Подтверждение выхода</DialogTitle>
        </DialogHeader>
        <DialogBody>
          Вы уверены, что хотите выйти из системы? Все несохраненные данные
          могут быть утеряны.
        </DialogBody>
        <DialogFooter>
          <Button onClick={() => setOpenConfirm(false)} variant="outlined">
            Отмена
          </Button>
          <Button
            onClick={() => {
              window.location.href =
                process.env.NODE_ENV === "development"
                  ? `${process.env.REACT_APP_BACKEND_HOST}/Auth/Logout/`
                  : "/Auth/Logout/";
            }}
          >
            Подтвердить
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
});

export default Header;
