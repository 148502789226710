import {makeAutoObservable} from "mobx";
import {BioInstanceDto, Page, Pages, ProfileDto} from "../types";
import { pages } from "../modules/utils/constants";

class Store {
    profile: ProfileDto | null;
    page: Page;
    pages: Pages;
    instances: BioInstanceDto[];

    constructor() {
        this.page = pages[0];
        this.pages = pages;
        this.profile = null;
        this.instances = [];
        makeAutoObservable(this);
    }

    setProfile = (profile: ProfileDto) => {
        this.profile = profile;
    };

    setPage = (page: Page) => {
      this.page = page;
    };

    setInstances = (instancesNew: BioInstanceDto[]) => {
      this.instances = instancesNew;
    }

    get getInstances () {
      return this.instances
    }

    get getPage () {
      return this.page
    }

    get getPages () {
      return this.pages
    }
    
    get getProfile() {
        return this.profile
    }
}

export const store = new Store();
