import { Button } from "@ornament-ui/kit/Button";
import { Typography } from "@ornament-ui/kit/Typography";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";
import styles from "./AgreementError.module.scss";

const AgreementError = () => {
    const isMobile = useIsMobile();

    return (
        <div className={styles.agreementError}>
            <img src="/warning.svg" alt="warning"></img>

            <div className={`${styles.mb} ${styles.agreementError__text}`}>
                <Typography className={styles.textMb12} variant={isMobile ? "text-l_strong" : "text-xl_strong"}>
                    Невозможно подключить биометрию
                </Typography>
                <Typography className={styles.textMb12} variant={isMobile ? "text-s" : "text-m"}>
                    Разрешите Face2 использовать ваши данные из единой биометрической системы на портале Госуслуг
                </Typography>

                <a
                    href={
                        process.env.NODE_ENV === "development"
                            ? `${process.env.REACT_APP_BACKEND_HOST}/Auth/Agreement`
                            : "/Auth/Agreement"
                    }
                >
                    <Button size={"s"}>Передать согласие</Button>
                </a>
            </div>
        </div>
    );
};

export default AgreementError;
