import {Typography} from "@ornament-ui/kit/__inner__/esm/components/Typography";
import logo from "../assets/sbpConnect.svg";
import styles from "../help.module.scss";
import {useNavigate} from "react-router-dom";


const ConnectSbpPrew = () => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.item}
      onClick={() => {
        navigate('connect')
      }}
    >
      <img src={logo} alt="logo sbp" />
      <div className={styles.item__info}>
        <Typography className={styles.item__title} variant={"text-xl_strong"}>Как подключить платежи</Typography>
        <Typography color={"disabled"} variant={"text-m"}>
          Памятка по использованию системы быстрых платежей (СБП) при переводе
          денежных средств между физическими лицами
        </Typography>
      </div>
    </div>
  );
};

export default ConnectSbpPrew;
