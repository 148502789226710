import React from "react";
import type { IconProps } from "@ornament-ui/icons";

const GosUslugiXsIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4701 8.10563C22.3786 6.91297 21.4628 5.38392 20.5471 4.64997C19.5173 3.89885 18.4473 3.20465 17.3419 2.57046C16.2449 1.91904 15.1139 1.32696 13.9536 0.796756C13.3339 0.543472 12.6693 0.418636 12 0.429783C11.3305 0.415691 10.6654 0.540638 10.0464 0.796756C8.88639 1.32734 7.75537 1.91941 6.65815 2.57046C4.8877 3.64079 3.45302 4.64997 3.45302 4.64997C2.77294 5.22018 2.24174 5.9382 1.89466 6.74171C1.76202 7.04877 2.00182 7.37157 2.33631 7.37157H3.20065C3.57132 7.37157 3.90436 7.1611 4.11857 6.8586C4.35008 6.53167 4.62479 6.23352 4.93766 5.973C4.93766 5.973 6.12224 5.14548 7.58407 4.2678C8.49001 3.73394 9.42388 3.24844 10.3817 2.81337C10.8927 2.60335 11.442 2.50089 11.9947 2.51245C12.5473 2.50331 13.0961 2.60567 13.6078 2.81337C14.5658 3.24814 15.4997 3.73364 16.4054 4.2678C17.3181 4.78784 18.2016 5.35709 19.0518 5.973C19.808 6.57484 20.5641 7.82866 20.6397 8.80664C20.6397 8.80664 20.8161 10.2611 20.8413 11.9838C20.8398 13.0478 20.7809 14.1109 20.6649 15.1685C20.489 16.276 19.916 17.2826 19.0518 18.0022C19.0518 18.0022 17.8673 18.8548 16.4054 19.7325C15.4995 20.2663 14.5656 20.7518 13.6078 21.1869C13.0968 21.3969 12.5475 21.4994 11.9947 21.4878C11.4427 21.4993 10.894 21.3995 10.3817 21.1944C9.42373 20.7597 8.48984 20.2741 7.58407 19.74C6.12224 18.8874 4.93766 18.0097 4.93766 18.0097C4.66034 17.789 4.38302 17.4805 4.13803 17.1302C3.92915 16.8315 3.59951 16.6287 3.235 16.6287H2.34942C2.01063 16.6287 1.77238 16.9585 1.91504 17.2658C2.29341 18.0807 2.87322 18.8642 3.45302 19.3289C3.45302 19.3289 4.8877 20.3992 6.65815 21.439C7.75516 22.0904 8.88621 22.6825 10.0464 23.2127C10.6669 23.4628 11.3314 23.5845 12 23.5705C12.6695 23.5846 13.3347 23.4596 13.9536 23.2035C15.1137 22.6729 16.2447 22.0808 17.3419 21.4298C19.1124 20.3595 20.5471 19.3197 20.5471 19.3197C21.5937 18.4422 22.2877 17.2146 22.5007 15.864C22.6411 14.5742 22.7125 13.2777 22.7143 11.9803C22.6838 9.87933 22.4701 8.10563 22.4701 8.10563Z"
        fill="url(#paint0_linear_220_1501)"
      />
      <path
        d="M1.28589 9.45553C1.28589 9.1999 1.49435 8.99268 1.7515 8.99268H8.96844C9.22558 8.99268 9.43404 9.1999 9.43404 9.45553V10.8441C9.43404 11.0997 9.22558 11.307 8.96844 11.307H1.28589V9.45553Z"
        fill="white"
      />
      <path
        d="M1.28564 12.6953H15.9523C16.2095 12.6953 16.4179 12.9025 16.4179 13.1582V14.5467C16.4179 14.8024 16.2095 15.0096 15.9523 15.0096H1.75125C1.4941 15.0096 1.28564 14.8024 1.28564 14.5467V12.6953Z"
        fill="#EE2F53"
      />
      <defs>
        <linearGradient
          id="paint0_linear_220_1501"
          x1="12.1893"
          y1="0.428711"
          x2="12.1893"
          y2="23.5716"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="0.354167" stop-color="white" />
          <stop offset="0.6875" stop-color="#EE2F53" />
          <stop offset="1" stop-color="#EE2F53" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GosUslugiXsIcon;
