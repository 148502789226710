import React from "react";

import { Outlet } from "react-router-dom";
import { observer } from "mobx-react";
import styles from "./mainContentWrapper.module.scss";
import Header from "./Header/Header";

type MainContentWrapperProps = {
  children?: React.ReactNode;
};

const MainContentWrapper: React.FC<MainContentWrapperProps> = observer(() => {
  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <Header />
        <Outlet />
      </main>
    </div>
  );
});

export default MainContentWrapper;
