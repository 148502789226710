import {ModalType} from "../modules/main/components/ConnectionModal/ConnectionModal";
import {BiometricsData, ConnectionDataCandidate, MenuListType, MoreData} from "../types";

export const urlParams = new URLSearchParams(window.location.search);

const parts = [
    {
        name: "extc",
        value: urlParams.get("extc"),
    },
    {
        name: "extd",
        value: urlParams.get("extd"),
    },
]
    .filter((x) => x.value)
    .map((x) => `${x.name}=${x.value}`)
    .join("&");

export const qParams = parts ? `?${parts}` : '';

const alParam = urlParams.get("al");
export const showPreload = alParam === "1";

export const initConnectionDataCandidate: ConnectionDataCandidate = {
    type: ModalType.connect,
    id: "",
    name: "",
    address: "",
    isOpen: false,
    description: '',
};

export const initMoreData: MoreData = {
    type: ModalType.connect,
    id: "",
    name: "",
    address: "",
    isOpen: false,
};

export const initBiometricsData: BiometricsData = {
    isOpen: false,
};

export const FACE2_URL = "https://face2.ru/";

export const menuList: MenuListType[] = [
    {
        path: FACE2_URL,
        title: "Сайт Face2",
    },
];

export const PermissionBiometricsTitle = 'Перед загрузкой фотографии дайте разрешение на обработку биометрических данных'
export const PermissionBiometricsText = '    Согласие на обработку персональных данных, необходимых для регистрации гражданина Российской Федерации в единой системе идентификации и аутентификации, и иных сведений, если такие сведения предусмотрены федеральными законами в указанной системе, и биометрических персональных данных гражданина Российской Федерации в единой информационной системе персональных данных, обеспечивающей обработку, включая сбор и хранение биометрических персональных данных, их проверку и передачу информации о степени их соответствия предоставленным биометрическим персональным данным гражданина Российской Федерации.' +
    '\n    1. Свободно, своей волей и в своем интересе даю конкретное, информированное и сознательное согласие на обработку следующих моих персональных данных: ' +
    '\n    а) Фамилия, имя, отчество (при наличии), пол, гражданство, дата и место рождения, номер основного документа, удостоверяющего личность, сведения о дате выдачи указанного документа и выдавшем его органе, адрес регистрации по месту жительства (пребывания), идентификационный номер налогоплательщика, страховой номер индивидуального лицевого счета, контактные данные (номер абонентского устройства подвижной радиотелефонной связи, адрес электронной почты), иные сведения, предусмотренные федеральными законами, а также необходимые для размещения данных в моей учетной записи в федеральной государственной информационной системе "Единая система идентификации и аутентификации в инфраструктуре, обеспечивающей информационно-технологическое взаимодействие информационных систем, используемых для предоставления государственных и муниципальных услуг в электронной форме" (далее - единая система идентификации и аутентификации); ' +
    '\n    б) Изображение лица, полученное с помощью фото- и видео- устройств, голос, полученный с помощью звукозаписывающих устройств, которые требуются для подтверждения моей идентификации с применением единой информационной системы персональных данных, обеспечивающей обработку, включая сбор и хранение биометрических персональных данных, их проверку и передачу информации о степени их соответствия предоставленным биометрическим персональным данным гражданина Российской Федерации (далее - единая биометрическая система) в соответствии с законодательством Российской Федерации. ' +
    '\n    2. Свободно, своей волей и в своем интересе даю конкретное, информированное и сознательное согласие на обработку моих персональных данных, указанных в пункте 1 настоящего согласия, в целях моей идентификации с применением информационных технологий: ' +
    '\n    а) Акционерному коммерческому банку «АК БАРС» (публичное акционерное общество), зарегистрированному по адресу Россия, Республика Татарстан, 420066, г. Казань, ул. Декабристов, д. 1, - на обработку (включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ) в том числе результата обработки моих персональных данных, обезличивание, блокирование, удаление, уничтожение) персональных данных, указанных в подпункте "б" пункта 1 настоящего согласия;' +
    '\n    б) Лицо, указанное в подпункте "а" пункта 2 настоящего согласия, вправе осуществлять автоматизированную обработку персональных данных, указанных в пункте 1 настоящего согласия, или их обработку без использования средств автоматизации, в том числе с передачей по каналам связи, защита которых обеспечивается путем реализации соответствующих правовых, организационных и технических мер, предусмотренных законодательством Российской Федерации о защите персональных данных.' +
    '\n    3. Для достижения цели, указанной в пункте 2 настоящего согласия: даю согласие лицу, указанному в подпункте "а" пункта 2 настоящего согласия, на проверку предоставленных мной сведений на предмет их полноты и достоверности, в том числе с использованием государственных и муниципальных информационных систем и ресурсов, а также путем направления указанными лицами запросов в иные органы и организации; ' +
    '\n    Соглашаюсь с тем, что лицо, указанное в подпункте "а" пункта 2 настоящего согласия, вправе поручить обработку моих персональных данных, указанных в пункте 1 настоящего согласия, другому лицу (лицам).' +
    'На основании поручения либо заключаемого с этим лицом (лицами) договора, в том числе государственного контракта, либо путем принятия государственным органом соответствующего акта (далее - поручение оператора).' +
    '\n    Я проинформирован, что ответственность передо мной за действия лица, обрабатывающего мои персональные данные по поручению оператора, несет оператор, выдавший соответствующее поручение. ' +
    '\n    4. Подтверждаю, что даю согласие на обработку моих персональных данных, указанных в пункте 1 настоящего согласия, лицу, указанному в подпункте "а" пункта 2 настоящего согласия. ' +
    '\n    5. Настоящее согласие действует со дня его подписания до дня его отзыва, но не более 50 лет. ' +
    '\n    6. Подтверждаю, что проинформирован (проинформирована) о возможности отзыва настоящего согласия на основании положений Федерального закона "О персональных данных" путем личного обращения или направления письменного обращения (в том числе в форме электронного документа, подписанного простой электронной подписью или усиленной квалифицированной электронной подписью), на имя лица, указанного в подпункте "а" пункта 2 настоящего согласия, как всех указанных в пункте 1 настоящего согласия персональных данных, так и отдельно биометрических персональных данных, указанных в подпункте "б" пункта 1 настоящего согласия;' +
    '\n    Отозвать настоящее согласие в целях прекращения обработки в единой системе идентификации и аутентификации персональных данных, указанных в подпункте "а" пункта 1 настоящего согласия; отозвать настоящее согласие в целях прекращения обработки в единой биометрической системе биометрических персональных данных, указанных в подпункте "б" пункта 1 настоящего согласия. ' +
    '\n    7. Я проинформирован (проинформирована), что лицо, указанное в подпункте "а" пункта 2 настоящего согласия, вправе после получения отзыва настоящего согласия, а равно после истечения срока действия настоящего согласия, продолжать обработку моих персональных данных при наличии оснований, предусмотренных частью 2 статьи 9 Федерального закона "О персональных данных".' +
    '\n    8. Подтверждаю, что проинформирован (проинформирована) о том, что мои биометрические данные будут переданы в Единую Биометрическую Систему после 25 октября 2023 года. Также я проинформирован, что до 25 октября 2023 года я могу отозвать свое согласие о передаче биометрических данных в Единую Биометрическую Систему путем удаления своей биометрии на данном портале. '













