import React from "react";
import {themeOrnamentDefault, ThemeProvider,} from "@ornament-ui/kit/ThemeProvider";
import "./app.scss";
import LoginPage from "./modules/login/LoginPage";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import PrivateRoute from "./modules/PrivateRoute";
import Index from "./modules/main/MainPage";
import MainContentWrapper from "./modules/PageContentWrapper/MainContentWrapper";
import Custom404 from "./modules/errors/404";
import SomethingWrong from "./modules/errors/SomethingWrong/SomethingWrong";
import StopListError from "./modules/errors/StopListError";
import PaymentMethod from "./modules/main/components/PaymentMethod";
import HistoryComponents from "./modules/main/components/HistoryComponents";
import HelpComponents from "./modules/main/components/HelpComponents";
import Objects from "./modules/main/components/Objects";
import ConnectSbpFull from "./modules/main/components/HelpComponents/components/ConnectSbpFull";
import AllHelps from "./modules/main/components/HelpComponents/components/AllHelps";

const router = createBrowserRouter([
  {
    element: <MainContentWrapper />,
    errorElement: <SomethingWrong />,
    children: [
      {
        path: '/',
        element: (
            <PrivateRoute>
              <Index />
            </PrivateRoute>
        ),
        children: [
          {
            path: "profile",
            element: <PaymentMethod />,
          },
          {
            path: 'object',
            element: <Objects />,
          },
          {
            path: 'history',
            element: <HistoryComponents />,
          },
          {
            path: 'help',
            element: <HelpComponents />,
            children: [
              {
                path: "",
                element: <AllHelps />,
              },
              {
                path: "connect",
                element: <ConnectSbpFull />,
              },
            ]
          },
        ]
      },
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "error",
        element: <SomethingWrong />,
      },

    ],
  },
  {
    path: "*",
    element: <Custom404 />,
    errorElement: <Custom404 />,
  },
  {
    path: "cbl_about",
    element: <StopListError />,
  },
]);

function App() {
  return (
    <ThemeProvider theme={themeOrnamentDefault} className="custom_theme">
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
