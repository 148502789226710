import React from 'react';
import styles from "../help.module.scss";
import { HelpItemType } from '..';
import ConnectSbpPrew from './ConnectSbpPrew';
import ConnectBioPrew from './ConnectBioPrew';
import {Typography} from "@ornament-ui/kit/Typography";


type AllHelpsProps = {
  setHelpItem: React.Dispatch<
    React.SetStateAction<HelpItemType>
  >

};

const AllHelps = () => {
  return (
    <>
        <Typography
            className={styles.permissions__title}
            variant={"heading-2xl"}
        >
            Популярные вопросы
        </Typography>
      <ConnectSbpPrew/>
      <ConnectBioPrew/>
    </>
  );
};

export default AllHelps;
