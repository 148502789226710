import cn from "classnames";
import {useNavigate} from "react-router-dom";
import styles from './404.module.css'

export default function Custom404() {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); // Переход на одну страницу назад в истории
    };

    return <div className={styles.pageContainer}>
        <div className={styles.container}>
            <div className={styles.image}>
                <img src={'/404.svg'} alt={'404 page'} />
            </div>

            <h1 className={styles.title}>Страница не найдена</h1>
            <p className={styles.description}>
                К сожалению, такой страницы не сущесвтует.
                <br />
                Вероятно, она была удалена или её здесь никогда не было
            </p>

            <button onClick={handleGoBack} className={cn('button_base', styles.wAuto)}>
                Вернуться обратно
            </button>
        </div>
    </div>
}