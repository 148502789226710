import {Typography} from "@ornament-ui/kit/__inner__/esm/components/Typography";
import styles from "../help.module.scss";
import img_1 from "../assets/connectSbpAllImage_1.png";
import img_2 from "../assets/connectSbpAllImage_2.png";
import img_3 from "../assets/connectSbpAllImage_3.png";
import img_4 from "../assets/connectSbpAllImage_4.png";
import img_5 from "../assets/connectSbpAllImage_5.png";
import img_6 from "../assets/connectSbpAllImage_6.png";
import img_6m from "../assets/connectSbpAllImage_6_m.png";
import img_7 from "../assets/connectSbpAllImage_7.png";
import img_8 from "../assets/connectSbpAllImage_8.png";
import img_9 from "../assets/connectSbpAllImage_9.png";
import img_10 from "../assets/connectSbpAllImage_10.png";
import {Button} from "@ornament-ui/kit/__inner__/esm/components/Button";
import {DownloadIcon} from "@ornament-ui/icons";
import {TickIcon} from "@ornament-ui/icons";
import {useIsMobile} from "../../../../utils/hooks/useIsMobile";
import SelectedHelp from "./SelectedHelp";

const ConnectSbpFull = () => {
    const isMobile = useIsMobile();
    return (
        <div>
            <SelectedHelp/>
            <Typography style={{marginTop: '20px'}} variant={"heading-2xl"}>Как подключить биометрию</Typography>
            <div className={styles.help}>
                <Typography style={{maxWidth: "570px"}} variant={"heading-xl"}>
                    Чтобы подключить биометрию и пользоваться системой Face2 в метрополитене необходимо
                </Typography>
                <ul>
                    <li className={styles.list}>
                        <div className={styles.list__title}>
                            <Typography variant={"text-l"} className={styles.list__number}>
                                1
                            </Typography>
                            <Typography variant={"text-l_strong"}>
                                Зарегистрировать упрощенную биометрию в Единой <br/> биометрической системе.
                            </Typography>
                        </div>

                        <div className={styles.list__content}>
                            {/* под список */}
                            <div className={styles.list__subTitle}>
                                <Typography variant={"text-l"}>A.</Typography>
                                <Typography variant={"text-l"}>
                                    Скачать и установить на свой смартфон мобильное приложение “Биометрия” по ссылке или
                                    отсканировав QR-код:
                                </Typography>
                            </div>
                            <div className={styles.list__imgWrapper}>
                                <img
                                    className={styles.list__img}
                                    src={img_1}
                                    alt="qr code и логотип есиа"
                                />
                                <Button
                                    className={styles.list__btn}
                                    onClick={() => {
                                        window.open(
                                            "https://play.google.com/store/apps/details?id=ru.rtlabs.mobile.ebs.gosuslugi.android",
                                            "_blank"
                                        );
                                    }}
                                >
                                    <DownloadIcon size="m" color="white"/>
                                    Скачать для Android
                                </Button>
                            </div>
                            {/* конец под списка */}

                            {/* под список */}
                            <div className={styles.list__subTitle}>
                                <Typography variant={"text-l"}>B.</Typography>
                                <Typography variant={"text-l"}>
                                    Авторизоваться с использованием логина и пароля от Госуслуг. В случае, если вы
                                    забыли логин или пароль от Госуслуг нажмите “не удаётся войти?” и восстановите свой
                                    доступ.
                                </Typography>
                            </div>
                            <div className={styles.list__imgWrapper}>
                                <img
                                    className={styles.list__img}
                                    src={img_2}
                                    alt="приложение гос услуг"
                                />
                            </div>
                            {/* конец под списка */}

                            {/* под список */}
                            <div className={styles.list__subTitle}>
                                <Typography variant={"text-l"}>C.</Typography>
                                <Typography variant={"text-l"}>
                                    Сфотографироваться, следуя инструкциям в приложении.
                                </Typography>
                            </div>
                            <div className={styles.list__imgWrapper}>
                                <img
                                    className={styles.list__img}
                                    src={img_3}
                                    alt="пример фотографии"
                                />
                            </div>
                            {/* конец под списка */}
                        </div>
                    </li>

                    <li className={styles.list}>
                        <div className={styles.list__title}>
                            <Typography variant={"text-l"} className={styles.list__number}>
                                2
                            </Typography>
                            <Typography variant={"text-l_strong"}>
                                Установить приложение СБПей на свой смартфон.
                            </Typography>
                        </div>
                    </li>

                    <li className={styles.list}>
                        <div className={styles.list__title}>
                            <Typography variant={"text-l"} className={styles.list__number}>
                                3
                            </Typography>
                            <Typography variant={"text-l_strong"}>
                                Выбрать банк из списка доступных для подключения:
                            </Typography>
                        </div>
                        <div className={styles.list__content}>
                            <ul>
                                <li className={styles.list__subList}>
                                    <TickIcon size={'s'} color={"#893EEC"}/>
                                    <Typography variant={"text-m"}>Ак Барс Банк</Typography>
                                </li>
                                <li className={styles.list__subList}>
                                    <TickIcon size={'s'} color={"#893EEC"}/>
                                    <Typography variant={"text-m"}>ВТБ</Typography>
                                </li>
                                <li className={styles.list__subList}>
                                    <TickIcon size={'s'} color={"#893EEC"}/>
                                    <Typography variant={"text-m"}>Альфа Банк</Typography>
                                </li>
                                <li className={styles.list__subList}>
                                    <TickIcon size={'s'} color={"#893EEC"}/>
                                    <Typography variant={"text-m"}>Т-банк (Тинькофф)</Typography>
                                </li>
                            </ul>
                            <div className={styles.list__imgWrapper}>
                                <img
                                    className={styles.list__img}
                                    src={img_4}
                                    alt="мобильное приложение госуслуг"
                                />
                            </div>
                        </div>
                    </li>

                    <li className={styles.list}>
                        <div className={styles.list__title}>
                            <Typography variant={"text-l"} className={styles.list__number}>
                                4
                            </Typography>
                            <Typography variant={"text-l_strong"}>
                                Заполнить поля “Номер телефона” и “Номер счета”. Номер счета вы можете узнать в онлайн кабинете или мобильном приложении своего Банка.
                            </Typography>
                        </div>

                        <div className={styles.list__content}>
                            {/* под список */}
                            <div className={styles.list__imgWrapper}>
                                <img
                                    className={styles.list__img}
                                    src={img_5}
                                    alt="Изображение приложения госуслуги"
                                />
                            </div>
                            {/* конец под списка */}
                        </div>
                    </li>

                    <li className={styles.list}>
                        <div className={styles.list__title}>
                            <Typography variant={"text-l"} className={styles.list__number}>
                                5
                            </Typography>
                            <Typography variant={"text-l_strong"}>
                                Авторизоваться в приложении СБПей через Госуслуги.
                            </Typography>
                        </div>
                    </li>

                    <li className={styles.list}>
                        <div className={styles.list__title}>
                            <Typography variant={"text-l"} className={styles.list__number}>
                                6
                            </Typography>
                            <Typography variant={"text-l_strong"}>
                                Передать согласие на обработку персональных данных.
                            </Typography>
                        </div>

                        <div className={styles.list__content}>
                            {/* под список */}
                            <div style={{paddingTop: 0}} className={styles.list__imgWrapper}>
                                <img
                                    style={{ maxWidth: isMobile ? '280px' : '500px'}}
                                    className={styles.list__img}
                                    src={isMobile ? img_6m : img_6}
                                    alt="Изображение приложения госуслуги"
                                />
                            </div>
                            {/* конец под списка */}
                        </div>
                    </li>

                    <li className={styles.list}>
                        <div className={styles.list__title}>
                            <Typography variant={"text-l"} className={styles.list__number}>
                                7
                            </Typography>
                            <Typography variant={"text-l_strong"}>
                                Перейти на вкладку счета и выбрать свой счёт.
                            </Typography>
                        </div>
                    </li>

                    <li className={styles.list}>
                        <div className={styles.list__title}>
                            <Typography variant={"text-l"} className={styles.list__number}>
                                8
                            </Typography>
                            <Typography variant={"text-l_strong"}>
                                Нажать на значок дополнительно (три точки).
                            </Typography>
                        </div>

                        <div className={styles.list__content}>
                            {/* под список */}
                            <div className={styles.list__imgWrapper}>
                                <img
                                    className={styles.list__img}
                                    src={img_7}
                                    alt="Изображение приложения госуслуги"
                                />
                            </div>
                            {/* конец под списка */}
                        </div>
                    </li>

                    <li className={styles.list}>
                        <div className={styles.list__title}>
                            <Typography variant={"text-l"} className={styles.list__number}>
                                9
                            </Typography>
                            <Typography variant={"text-l_strong"}>
                                Выбрать пункт “Подключить биометрию”.
                            </Typography>
                        </div>

                        <div className={styles.list__content}>
                            {/* под список */}
                            <div className={styles.list__imgWrapper}>
                                <img
                                    className={styles.list__img}
                                    src={img_8}
                                    alt="Изображение приложения"
                                />
                            </div>
                            {/* конец под списка */}
                        </div>
                    </li>

                    <li className={styles.list}>
                        <div className={styles.list__title}>
                            <Typography variant={"text-l"} className={styles.list__number}>
                                10
                            </Typography>
                            <Typography variant={"text-l_strong"}>
                                Подтвердить подключение.
                            </Typography>
                        </div>

                        <div className={styles.list__content}>
                            {/* под список */}
                            <div className={styles.list__imgWrapper}>
                                <img
                                    className={styles.list__img}
                                    src={img_9}
                                    alt="Изображение приложения"
                                />
                            </div>
                            {/* конец под списка */}
                        </div>
                    </li>

                    <li className={styles.list}>
                        <div className={styles.list__title}>
                            <Typography variant={"text-l"} className={styles.list__number}>
                                11
                            </Typography>
                            <Typography variant={"text-l_strong"}>
                                Дождаться обработки данных после чего ввести данные для логина через Госуслуги.
                            </Typography>
                        </div>

                        <div className={styles.list__content}>
                            {/* под список */}
                            <div className={styles.list__imgWrapper}>
                                <img
                                    className={styles.list__img}
                                    src={img_2}
                                    alt="Изображение приложения"
                                />
                            </div>
                            {/* конец под списка */}
                        </div>
                    </li>

                    <li className={styles.list}>
                        <div className={styles.list__title}>
                            <Typography variant={"text-l"} className={styles.list__number}>
                                12
                            </Typography>
                            <Typography variant={"text-l_strong"}>
                                После обработки данных от Госуслуг должно появиться сообщение об успешности подключения биометрии и значок биометрии в карточке счёта.
                            </Typography>
                        </div>

                        <div className={styles.list__content}>
                            {/* под список */}
                            <div className={styles.list__imgWrapper}>
                                <img
                                    className={styles.list__img}
                                    src={img_10}
                                    alt="Изображение приложения"
                                />
                            </div>
                            {/* конец под списка */}
                        </div>
                    </li>
                    <li className={styles.list}>
                        <div className={styles.list__title}>
                            <Typography variant={"text-l"} className={styles.list__number}>
                                13
                            </Typography>
                            <Typography variant={"text-l_strong"}>
                                На этом регистрация в системе Face2 завершена.
                            </Typography>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        // <div>
        //   <Typography variant={"heading-2xl"}>Популярные вопросы</Typography>
        //   <div className={styles.help}>
        //     <Typography style={{ maxWidth: "570px" }} variant={"heading-xl"}>
        //       Чтобы подключить биометрию и пользоваться системой Face2 в
        //       метрополитене необходимо
        //     </Typography>
        //     <ul>
        //       <li className={styles.list}>
        //         <div className={styles.list__title}>
        //           <Typography variant={"text-l"} className={styles.list__number}>
        //             1
        //           </Typography>
        //           <Typography variant={"text-l_strong"}>
        //             Зарегистрировать упрощенную биометрию в Единой <br />{" "}
        //             биометрической системе
        //           </Typography>
        //         </div>
        //
        //         <div className={styles.list__content}>
        //           {/* под список */}
        //           <div className={styles.list__subTitle}>
        //             <Typography variant={"text-l"}>A.</Typography>
        //             <Typography variant={"text-l"}>
        //               Скачать и установить на свой смартфон мобильное приложение
        //               “Биометрия” по ссылке или отсканировав QR-код:
        //             </Typography>
        //           </div>
        //           <div className={styles.list__imgWrapper}>
        //             <div className={styles.list__imgWrpLong}>
        //               <img
        //                 className={`${styles.list__img} ${styles.tempImg}`}
        //                 src={img_1}
        //                 alt="qr code и логотип есиа"
        //               />
        //               <div className={styles.list__btnGroupe}>
        //                 <Button
        //                   className={styles.list__btn}
        //                   onClick={() => {
        //                     window.open(
        //                       "https://play.google.com/store/apps/details?id=ru.rtlabs.mobile.ebs.gosuslugi.android",
        //                       "_blank"
        //                     );
        //                   }}
        //                 >
        //                   <DownloadIcon size="m" color="white" />
        //                   Скачать для Android
        //                 </Button>
        //                 <Button
        //                   className={styles.list__btn}
        //                   onClick={() => {
        //                     window.open(
        //                       "https://play.google.com/store/apps/details?id=ru.rtlabs.mobile.ebs.gosuslugi.android",
        //                       "_blank"
        //                     );
        //                   }}
        //                 >
        //                   <DownloadIcon size="m" color="white" />
        //                   Скачать для IOS
        //                 </Button>
        //               </div>
        //             </div>
        //           </div>
        //           {/* конец под списка */}
        //
        //           {/* под список */}
        //           <div className={styles.list__subTitle}>
        //             <Typography variant={"text-l"}>B.</Typography>
        //             <Typography variant={"text-l"}>
        //               Авторизоваться с использованием логина и пароля от Госуслуг. В
        //               случае, если вы забыли логин или пароль от Госуслуг нажмите
        //               “не удаётся войти?” и восстановите свой доступ.
        //             </Typography>
        //           </div>
        //           <div className={styles.list__imgWrapper}>
        //             <img
        //               className={styles.list__img}
        //               src={img_2}
        //               alt="приложение гос услуг"
        //             />
        //           </div>
        //           {/* конец под списка */}
        //
        //           {/* под список */}
        //           <div className={styles.list__subTitle}>
        //             <Typography variant={"text-l"}>C.</Typography>
        //             <Typography variant={"text-l"}>
        //               Сфотографироваться, следуя инструкциям в приложении.
        //             </Typography>
        //           </div>
        //           <div className={styles.list__imgWrapper}>
        //             <img
        //               className={styles.list__img}
        //               src={img_3}
        //               alt="пример фотографии"
        //             />
        //           </div>
        //           {/* конец под списка */}
        //         </div>
        //       </li>
        //
        //       <li className={styles.list}>
        //         <div className={styles.list__title}>
        //           <Typography variant={"text-l"} className={styles.list__number}>
        //             2
        //           </Typography>
        //           <Typography variant={"text-l_strong"}>
        //             Подключить биометрию
        //           </Typography>
        //         </div>
        //         <div className={styles.list__content}>
        //           {/* под список */}
        //           <div className={styles.list__subTitle}>
        //             <Typography variant={"text-l"}>A.</Typography>
        //             <Typography variant={"text-l"}>
        //               Отсканировать QR-код и перейти по ссылке.
        //             </Typography>
        //           </div>
        //           <div className={styles.list__imgWrapper}>
        //             <img
        //               className={styles.list__img}
        //               src={img_4}
        //               alt="Изображение приложения госуслуги"
        //             />
        //           </div>
        //           {/* конец под списка */}
        //           {/* под список */}
        //           <div className={styles.list__subTitle}>
        //             <Typography variant={"text-l"}>B.</Typography>
        //             <Typography variant={"text-l"}>
        //               На странице авторизации Face2 необходимо нажать на кнопку 
        //               «Войти через Госуслуги»
        //             </Typography>
        //           </div>
        //           <div className={styles.list__imgWrapper}>
        //             <img
        //               className={styles.list__img}
        //               src={img_5}
        //               alt="Изображение приложения госуслуги"
        //             />
        //           </div>
        //           {/* конец под списка */}
        //           {/* под список */}
        //           <div className={styles.list__subTitle}>
        //             <Typography variant={"text-l"}>C.</Typography>
        //             <Typography variant={"text-l"}>
        //               В форме ввести ваш логин-пароль от портала Госуслуги, нажать
        //               кнопку «Войти»
        //             </Typography>
        //           </div>
        //           <div className={styles.list__imgWrapper}>
        //             <img
        //               className={styles.list__img}
        //               src={img_6}
        //               alt="Изображение приложения госуслуги"
        //             />
        //           </div>
        //           {/* конец под списка */}
        //           {/* под список */}
        //           <div className={styles.list__subTitle}>
        //             <Typography variant={"text-l"}>D.</Typography>
        //             <Typography variant={"text-l"}>
        //               В открывшемся окне нажать на кнопку «Предоставить»
        //             </Typography>
        //           </div>
        //           <div
        //             className={styles.list__imgWrapper}
        //             style={{ paddingTop: 0 }}
        //           >
        //             <img
        //               className={styles.list__img}
        //               src={img_7}
        //               alt="Изображение приложения госуслуги"
        //             />
        //           </div>
        //           {/* конец под списка */}
        //           {/* под список */}
        //           <div className={styles.list__subTitle}>
        //             <Typography variant={"text-l"}>E.</Typography>
        //             <Typography variant={"text-l"}>
        //               В следующем окне нажать на «Разрешить»
        //             </Typography>
        //           </div>
        //           <div
        //             className={styles.list__imgWrapper}
        //             style={{ paddingTop: 0 }}
        //           >
        //             <img
        //               className={styles.list__img}
        //               src={img_8}
        //               alt="Изображение приложения госуслуги"
        //             />
        //           </div>
        //           {/* конец под списка */}
        //           {/* под список */}
        //           <div className={styles.list__subTitle}>
        //             <Typography variant={"text-l"}>F.</Typography>
        //             <Typography variant={"text-l"}>
        //               Далее вы будете переведены в личный кабинет Face2.
        //             </Typography>
        //           </div>
        //           {/* конец под списка */}
        //         </div>
        //       </li>
        //
        //       <li className={styles.list}>
        //         <div className={styles.list__title}>
        //           <Typography variant={"text-l"} className={styles.list__number}>
        //             3
        //           </Typography>
        //           <Typography variant={"text-l_strong"}>
        //             На этом регистрация в системе Face2 завершена.
        //           </Typography>
        //         </div>
        //       </li>
        //     </ul>
        //   </div>
        // </div>
    );
};

export default ConnectSbpFull;
