import {Button} from "@ornament-ui/kit/Button";
import {Container} from "@ornament-ui/kit/Container";
import {Typography} from "@ornament-ui/kit/Typography";
import Plug from "./assets/plug.png";
import Socket from "./assets/socket.png";
import styles from "./SomethingWrong.module.scss";
import cn from "classnames";
import {useLocation, useRouteError} from "react-router-dom";
import {SomethingWrongProps} from "../../../types";
import {useEffect} from "react";
import {sendMetrik} from "../../utils/metriks";

const SomethingWrong: React.FC<SomethingWrongProps> = ({ title, text }) => {
    const search = useLocation().search;
    const t = new URLSearchParams(search).get("t");
    const e = new URLSearchParams(search).get("e");

    const error:any = useRouteError()

    const titleValue = t || title;
    const textValue = e || text;

    useEffect(() => {
        sendMetrik('reachGoal','errorPage', {MessageError: String(error?.stack ? error?.stack : error)})
    }, [])

    return (
        <Container size={"l"} className={cn(styles.container, styles.errContainer)}>
            <div className={styles.errorTextSide}>
                <Typography variant={"heading-2xl"} className={styles.errTitle}>
                    {titleValue ? titleValue : "Что-то пошло не так"}
                </Typography>
                <div className={styles.errTextWrapper}>
                    <Typography variant={"text-m"} className={styles.errDescription}>
                        {textValue ? textValue : "Попробуйте перезагрузить страницу"}
                    </Typography>
                </div>
                {!titleValue && !textValue && (
                    <a href="/">
                        <Button size={"s"}>Перезагрузить</Button>
                    </a>
                )}
            </div>
            <div className={styles.errorImage}>
                <img src={Plug} alt={"ошибка соединения"} />
                <img src={Socket} alt={"ошибка соединения"} />
            </div>
        </Container>
    );
};

export default SomethingWrong;
