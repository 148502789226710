import { Typography } from "@ornament-ui/kit/Typography";
import styles from "./help.module.scss";
import { SomethingWrongProps } from "../../../types";
import { Button } from "@ornament-ui/kit/__inner__/esm/components/Button";
import img_1 from "./assets/1.png";
import img_2 from "./assets/2.png";
import img_3 from "./assets/3.png";
import img_4 from "./assets/4.png";
import { useNavigate } from "react-router-dom";

const StopListError: React.FC<SomethingWrongProps> = ({ title, text }) => {
  let navigate = useNavigate();
  // useEffect(() => {
  //     sendMetrik('reachGoal','errorPage', {MessageError: String(error?.stack ? error?.stack : error)})
  // }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <img src={"./face2logo.svg"} alt={"логотип face 2"} />
        <div className={styles.help}>
          <Typography style={{ maxWidth: "570px" }} variant={"heading-xl"}>
            Чтобы убрать карту из стоп-листа необходимо:
          </Typography>
          <ul>
            <li className={styles.list}>
              <div className={styles.list__title}>
                <Typography variant={"text-l"} className={styles.list__number}>
                  1
                </Typography>
                <Typography variant={"text-l_strong"}>
                  Авторизироваться в нашем сервисе через Госуслуги
                </Typography>
              </div>

              <div className={styles.list__content}>
                {/* под список */}
                <div className={styles.list__subTitle}>
                  <Typography variant={"text-l"}>A.</Typography>
                  <Typography variant={"text-l"}>
                    Перейти на сайт {' '}
                    <span
                    className={styles.link}
                      onClick={() => {
                        navigate('/')
                      }}
                    >
                      личный кабинет Face2
                    </span>
                    {' '}
                    и нажать на кнопку “Войти через Госуслуги”.
                  </Typography>
                </div>
                <div className={styles.list__imgWrapper}>
                  <img
                    className={styles.list__img}
                    src={img_1}
                    alt="qr code и логотип есиа"
                  />
                </div>
                {/* конец под списка */}

                {/* под список */}
                <div className={styles.list__subTitle}>
                  <Typography variant={"text-l"}>B.</Typography>
                  <Typography variant={"text-l"}>
                    Авторизоваться с использованием логина и пароля от Госуслуг.
                    В случае, если вы забыли логин или пароль от Госуслуг,
                    нажмите “не удаётся войти?” и восстановите свой доступ.
                  </Typography>
                </div>
                <div className={styles.list__imgWrapper}>
                  <img
                    className={styles.list__img}
                    src={img_2}
                    alt="приложение гос услуг"
                  />
                </div>
                {/* конец под списка */}
              </div>
            </li>

            <li className={styles.list}>
              <div className={styles.list__title}>
                <Typography variant={"text-l"} className={styles.list__number}>
                  2
                </Typography>
                <Typography variant={"text-l_strong"}>
                  Перейдите в раздел "Профиль" и в способах оплаты нажмите на
                  кнопку “Оплатить сейчас”
                </Typography>
              </div>
              <div className={styles.list__content}>
                <div className={styles.list__imgWrapper}>
                  <img
                    className={styles.list__img}
                    src={img_3}
                    alt="мобильное приложение госуслуг"
                  />
                </div>
              </div>
            </li>

            <li className={styles.list}>
              <div className={styles.list__title}>
                <Typography variant={"text-l"} className={styles.list__number}>
                  3
                </Typography>
                <Typography variant={"text-l_strong"}>
                  После оплаты статус способа оплаты сменится на "Активный"
                </Typography>
              </div>
              <div className={styles.list__content}>
                <div className={styles.list__imgWrapper}>
                  <img
                    className={styles.list__img}
                    src={img_4}
                    alt="мобильное приложение госуслуг"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <Button onClick={() => {
          navigate('/')
        }} className={styles.btn}>Перейти в личный кабинет</Button>
        <footer className={styles.footer}>
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.location.assign("https://face2.ru/");
            }}
            color={"action"}
          >
            Сайт Face2
          </Typography>
          {/* <Typography color="disabled">АО «Социальная карта» 2024</Typography> */}
        </footer>
      </div>
    </div>
  );
};

export default StopListError;
