import axios, { AxiosResponse } from "axios";
import {
  AttachBioInstanceDto,
  BioInstanceDto, DebtPaymentsDto, DebtPayOffDto, DebtStatus,
  EnventsTransactions,
  KdwAttachPhotoDto,
  ProfileDto,
} from "../types";
import { format } from "date-fns";

const httpService = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_HOST,
  headers: {
    accept: "application/json",
  },
});

export const isAuthenticated = () =>
  httpService.defaults.headers.common["Authorization"];

export const isLoginAlredy = () => {
  return !!localStorage.getItem("au");
};

export const getDate = (date: string) => {
  return format(new Date(date), "dd.MM.yyyy");
};

export const getDateTime = (date: string) => {
  return format(new Date(date), "dd.MM.yyyy - HH:mm");
};


export const reAuthenticate = async (): Promise<Boolean> => {
  console.log("reAuthenticate");

  try {
    const accessTokenResult = await httpService.post(
      "/Auth/AccessToken",
      {},
      { withCredentials: true }
    );

    if (accessTokenResult.data.accessToken) {
      localStorage.setItem("au", "s");

      httpService.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessTokenResult.data.accessToken}`;
      return true;
    } else {
      httpService.defaults.headers.common["Authorization"] = null;
      localStorage.removeItem("au");
      return false;
    }
  } catch (e) {
    httpService.defaults.headers.common["Authorization"] = null;
    localStorage.removeItem("au");
    return false;
  }
};

httpService.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status === 401) {
      const { config } = error;
      if (config.headers["rtr"]) {
        return Promise.reject(error);
      }

      try {
        const success = await reAuthenticate();

        if (!success) {
          return Promise.reject(error);
        }

        config.headers["rtr"] = "_";
        return httpService.request(config);
      } catch (e) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

class ProfileContext {
  /**
   * Запрос информации по пользователю
   */
  async getProfile(): Promise<AxiosResponse<ProfileDto>> {
    return await httpService.get("/api/Profile");
  }

  /**
   * Запрос не оплаченных операций
   */
  async getDebtPayments(): Promise<AxiosResponse<DebtPaymentsDto[]>> {
    return await httpService.get(`${process.env.REACT_APP_BACKEND_TRANSACTIONS_HOST}api/Debt/Payments`);
  }

  /**
   * Запрос не оплаченных операций
   */
  async getDebtStatus(paymentId: string): Promise<AxiosResponse<DebtStatus>> {
    return await httpService.get(`${process.env.REACT_APP_BACKEND_TRANSACTIONS_HOST}api/Debt/Status/${paymentId}`);
  }

  /**
   * Запрос не оплаченных операций
   */
  async getDebtPayOff(paymentId: string): Promise<AxiosResponse<DebtPayOffDto>> {
    return await httpService.post(`${process.env.REACT_APP_BACKEND_TRANSACTIONS_HOST}api/Debt/PayOff`, {paymentId});
  }

  /**
   * Запрос объектов
   */
  async getInstances(): Promise<AxiosResponse<BioInstanceDto[]>> {
    return await httpService.get("/api/Profile/BioInstances");
  }

  /**
   * Добавить объект
   */
  async putInstance(
    request: AttachBioInstanceDto
  ): Promise<AxiosResponse<BioInstanceDto[]>> {
    return await httpService.put("/api/Profile/BioInstances", request);
  }

  /**
   * Валидация фотографии пользователя (загрузка фото на сервер)
   */
  async KdwAttachPhoto(
    request: any
  ): Promise<AxiosResponse<KdwAttachPhotoDto>> {
    return await httpService.post("/api/Profile/KdwAttachPhoto", request);
  }

  /**
   * Получение списка транзакций
   */
  async getTransactions(
    from: any,
    personId: string
  ): Promise<AxiosResponse<EnventsTransactions>> {
    return await httpService.post(
      `${process.env.REACT_APP_BACKEND_TRANSACTIONS_HOST}api/Person/Transactions
`,
      { From: from, personId: personId }
    );
  }
}

const profileContext = new ProfileContext();

export default profileContext;
