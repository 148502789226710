import { Typography } from "@ornament-ui/kit/__inner__/esm/components/Typography";
import styles from "../help.module.scss";
import logo from "../assets/bioConnect.svg";

const ConnectBioPrew = () => {
  return (
    <div
      className={styles.item}
      onClick={() => {
        window.open("https://ebs.ru/citizens/#how_register", "_blank");
      }}
    >
      <img src={logo} alt="logo sbp" />
      <div className={styles.item__info}>
        <Typography className={styles.item__title}  variant={"text-xl_strong"}>
          Как подключить биометрию
        </Typography>
        <Typography color={"disabled"} variant={"text-m"}>
          Зарегистрируйтесь в Единой биометрической системе и пользуйтесь
          преимуществами современного безопасного способа идентификации личности
        </Typography>
      </div>
    </div>
  );
};

export default ConnectBioPrew;
