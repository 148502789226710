import React from "react";
import { Skeleton } from "@ornament-ui/kit/Skeleton";
import styles from "./skeletonCard.module.scss";
import { Grid, GridItem } from "@ornament-ui/kit/__inner__/esm/components/Grid";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";

const SkeletonCard = () => {
  const isMobile = useIsMobile();

  return (
    <li className={styles.cardItem}>
      <Grid
        cols={isMobile ? 1 : 12}
        className={styles.cardItem__grid}
        gap={"s"}
      >
        {/* Icon */}
        <GridItem
          colStart={1}
          rowStart={isMobile ? 1 : 1}
          row={isMobile ? 1 : 3}
          col={isMobile ? 1 : 2}
          className={styles.cardItem__grid_item}
        >
          <Skeleton
            className={styles.skeleton__icon}
            variant={"rectangular"}
            width={isMobile ? 90 : "70%"}
            height={isMobile ? 90 : 80}
          />
        </GridItem>

        {/* Название */}
        <GridItem
          rowStart={isMobile ? 2 : 1}
          col={isMobile ? 1 : 6}
          colStart={isMobile ? 1 : 3}
          className={styles.cardItem__grid_item}
        >
          <Skeleton
            className={styles.skeleton__firstTitle}
            variant={"rectangular"}
            width={250}
          />
          <Skeleton
            className={styles.skeleton__tag}
            variant={"rectangular"}
            width={50}
          />
          <Skeleton
            className={styles.skeleton__adress}
            variant={"rectangular"}
            width={200}
          />
        </GridItem>

        {/* Кнопка */}
        <GridItem
          colStart={isMobile ? 1 : 9}
          rowStart={isMobile ? 3 : 1}
          col={isMobile ? 1 : 4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: isMobile ? "start" : "end",
            marginLeft: isMobile ? "" : "auto",
          }}
          className={styles.cardItem__grid_item}
        >
          <Skeleton
            className={styles.skeleton__btn}
            variant={"rectangular"}
            width={210}
          />
        </GridItem>
      </Grid>
    </li>
  );
};

export default SkeletonCard;
