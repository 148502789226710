import {useBreakpoints} from "@ornament-ui/kit/useBreakpoints";

export const useIsMobile = () => {
  const breakpoints = useBreakpoints({
    xm: '(min-width: 768px)'
  });
  const {
    s,
    m,
    l,
    xm
  } = breakpoints;
  const isMobile = !m;

    return isMobile;
}
