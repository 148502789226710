import styles from "../historyComponents.module.scss";
import metroIcon from "./assets/seccess.svg";
import metroIconError from "./assets/error.svg";
import {Typography} from "@ornament-ui/kit/__inner__/esm/components/Typography";
import {Button} from "@ornament-ui/kit/__inner__/esm/components/Button";
import {getDate} from "../../../../../services/profileContext";
import {DebtPaymentsDto, Transaction} from "../../../../../types";

type EventComponentProps = {
    event: Transaction | DebtPaymentsDto;
    setActiveEvent: (arg0: Transaction) => void;
    setIsOpenModalEvent: (arg0: boolean) => void;
    error?: boolean;
    setActiveItemBlackListPay?: (arg0: DebtPaymentsDto) => void;
    setIsOpenBlackListPayModal?: (arg0: boolean) => void;
};

const EventComponent = ({
                            event,
                            setIsOpenModalEvent,
                            setActiveEvent,
                            error = false,
                            setActiveItemBlackListPay,
                            setIsOpenBlackListPayModal
                        }: EventComponentProps) => {
    const isDebtPaymentsDto = (event: Transaction | DebtPaymentsDto): event is DebtPaymentsDto => {
        return (event as DebtPaymentsDto).paymentId !== undefined;
    };

    return (
        <li
            onClick={() => {
                if (error && setActiveItemBlackListPay && isDebtPaymentsDto(event) && setIsOpenBlackListPayModal) {
                    setActiveItemBlackListPay(event)
                    setIsOpenBlackListPayModal(true)
                } else {
                    setActiveEvent(event);
                    setIsOpenModalEvent(true);
                }

            }}
            className={styles.history__list}
        >
            <div className={styles.history__eventItem}>
                <div className={styles.history__eventIcon}>
                    <img
                        src={error ? metroIconError : metroIcon}
                        alt="Иконка места оплаты"
                    />
                </div>
                <div className={styles.history__eventInfo}>
                    <div>
                        <Typography variant="text-l_strong">{event.text}</Typography>
                        <Typography color="disabled" variant="text-s">
                            {getDate(event.time)}
                        </Typography>
                        {error && (
                            <Typography color="error" variant="text-m">
                                Оплата не прошла
                            </Typography>
                        )}
                    </div>
                    <div className={styles.history__eventPay}>
                        <Typography variant="text-l_strong">
                            {event.amount / 100}₽
                        </Typography>
                    </div>
                </div>
            </div>
            {error && <Button
                onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (setActiveItemBlackListPay && isDebtPaymentsDto(event) && setIsOpenBlackListPayModal) {
                        setActiveItemBlackListPay(event)
                        setIsOpenBlackListPayModal(true)
                    }
                }}
                variant="ghost">Оплатить сейчас</Button>}
        </li>
    );
};

export default EventComponent;
