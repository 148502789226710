import {Typography} from "@ornament-ui/kit/__inner__/esm/components/Typography";
import styles from "./historyComponents.module.scss";
import EventComponent from "./Components/EventComponent";
import EventModal from "./Components/EventModal";
import React, {useEffect, useState} from "react";
import profileContext, {getDate} from "../../../../services/profileContext";
import {toJS} from "mobx";
import {store} from "../../../../store/store";
import {Button} from "@ornament-ui/kit/__inner__/esm/components/Button";
import {DebtPaymentsDto, Transaction, TransactionsGroup} from "../../../../types";
import SkeletonHistory from "../SkeletonHistory/SkeletonHistory";
import BlackListPayModal from "../BlackListPayModal";

const HistoryComponents = () => {
    const [isOpenModalEvent, setIsOpenModalEvent] = useState<boolean>(false);
    const [events, setEvents] = useState<TransactionsGroup[]>([]);
    const [activeEvent, setActiveEvent] = useState<Transaction | null>(null);
    const [disabledBtn, setDisabledBtn] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isOpenBlackListPayModal, setIsOpenBlackListPayModal] = useState<boolean>(false);
    const [errorPay, setErrorPay] = useState<DebtPaymentsDto[] | null>(null);
    const [activeItemBlackListPay, setActiveItemBlackListPay] = useState<DebtPaymentsDto | null>(null);

    useEffect(() => {
        profileContext.getDebtPayments()
            .then((res) => {
                if (res.data && res.data.length > 0) {
                    setErrorPay(res.data)
                } else if (errorPay && errorPay?.length > 0 && res.data.length === 0) {
                    setErrorPay(null)
                }
            })
        if (!isOpenBlackListPayModal && events.length > 0) getEvents(null, `${toJS(store?.profile?.id)}`);
    }, [isOpenBlackListPayModal])

    useEffect(() => {
        setLoading(true);
        getEvents(null, `${toJS(store?.profile?.id)}`);
    }, []);

    function checkDateExists(dateToCheck: string) {
        return events.some((transaction) => transaction.date === dateToCheck);
    }

    const getEvents = (date: string | null, id: string) => {
        try {
            profileContext.getTransactions(date, id).then((res) => {
                if (date) {
                    const newEvents = res.data.transactionsGroups.filter(
                        (item) => !checkDateExists(item.date)
                    );

                    if (!newEvents.length) {
                        setDisabledBtn(true);
                    }
                    setEvents((prev) => [...prev, ...newEvents]);
                } else {
                    setEvents(res.data.transactionsGroups);
                }
                setLoading(false);
            })
                .catch((e) => {
                    console.log(e);
                    setLoading(false);
                })
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const getDateNow = (event: TransactionsGroup) => {
        if (event.isToday) {
            return "Сегодня";
        } else if (event.isYesterday) {
            return "Вчера";
        } else {
            return getDate(event.date);
        }
    };

    return (
        <>
            <Typography
                className={styles.title}
                variant={"heading-2xl"}
            >
                История платежей
            </Typography>
            <div className={styles.cardListContainer}>
                <div className={styles.wrapper}>
                    <section className={styles.history}>
                        {loading ? (
                            <ul className={styles.cardList}>
                                <SkeletonHistory/>
                                <SkeletonHistory/>
                                <SkeletonHistory/>
                                <SkeletonHistory/>
                                <SkeletonHistory/>
                                <SkeletonHistory/>
                                <SkeletonHistory/>
                                <SkeletonHistory/>
                            </ul>
                        ) : events.length > 0 ? (
                            <>
                                {
                                    errorPay &&
                                    <div className={styles.history__section}>
                                        <Typography variant={"heading-xl"}>
                                            Не оплаченные
                                        </Typography>
                                        <ul className={styles.history__event}>
                                            {errorPay.map((item) => {
                                                return (
                                                    <EventComponent
                                                        setIsOpenBlackListPayModal={setIsOpenBlackListPayModal}
                                                        setActiveItemBlackListPay={setActiveItemBlackListPay}
                                                        error={true}
                                                        key={item.time}
                                                        event={item}
                                                        setIsOpenModalEvent={setIsOpenModalEvent}
                                                        setActiveEvent={setActiveEvent}
                                                    />
                                                );
                                            })}
                                        </ul>
                                    </div>

                                }
                                {events.map((item, index) => {
                                    return (
                                        <div key={index} className={styles.history__section}>
                                            <Typography variant={"heading-xl"}>
                                                {getDateNow(item)}
                                            </Typography>
                                            <ul className={styles.history__event}>
                                                {item.transactions.map((event) => {
                                                    return (
                                                        <EventComponent
                                                            key={event.time}
                                                            event={event}
                                                            setIsOpenModalEvent={setIsOpenModalEvent}
                                                            setActiveEvent={setActiveEvent}
                                                        />
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    );
                                })}
                                <Button
                                    disabled={disabledBtn}
                                    onClick={() =>
                                        getEvents(
                                            events[events.length - 1].date,
                                            `${toJS(store?.profile?.id)}`
                                        )
                                    }
                                    variant="ghost"
                                    style={{marginBottom: "20px"}}
                                >
                                    Показать еще
                                </Button>
                            </>
                        ) : (
                            <Typography
                                className={styles.notFilterFoundText}
                                variant={"heading-xl"}
                                color={"tertiary"}
                            >
                                Пока нет платежей
                            </Typography>
                        )}
                    </section>
                    {activeEvent && (
                        <EventModal
                            isOpen={isOpenModalEvent}
                            closeModal={setIsOpenModalEvent}
                            event={activeEvent}
                        />
                    )}
                </div>
            </div>
            {
                isOpenBlackListPayModal && <BlackListPayModal
                    isOpen={isOpenBlackListPayModal}
                    closeModal={setIsOpenBlackListPayModal}
                    activeItemBlackListPay={activeItemBlackListPay}
                    setActiveItemBlackListPay={setActiveItemBlackListPay}
                />
            }
        </>

    );
};

export default HistoryComponents;
