import {Modal} from "@ornament-ui/kit/__inner__/esm/components/Modal";
import styles from "./BlackListPayModal.module.scss";
import {Typography} from "@ornament-ui/kit/__inner__/esm/components/Typography";
import {IconButton} from "@ornament-ui/kit/__inner__/esm/components/IconButton";
import {CloseIcon} from "@ornament-ui/icons";
import React, {useEffect, useRef, useState} from "react";
import profileContext, {getDateTime} from "../../../../services/profileContext";
import {DebtPaymentsDto, DebtPayOffDto} from "../../../../types";
import {Loader} from "@ornament-ui/kit/Loader";
import metroIcon from "../HistoryComponents/Components/assets/error.svg";
import {Button} from "@ornament-ui/kit/Button";
import success from "../ConnectionModal/assets/success.png";
import jsQR from "jsqr";
import {Divider} from "@ornament-ui/kit/__inner__/esm/components/Divider";
import noQr from "./no_qr.svg"

type Props = {
    isOpen: boolean;
    closeModal: (arg: boolean) => void;
    setActiveItemBlackListPay: (arg: null) => void;
    activeItemBlackListPay: DebtPaymentsDto | null;
};

const BlackListPayModal = ({isOpen, closeModal, activeItemBlackListPay, setActiveItemBlackListPay}: Props) => {
    const [dataPay, setDataPay] = useState<DebtPayOffDto | null>(null);
    const [seconds, setSeconds] = useState(0);
    const [loadButton, setLoadButton] = useState<boolean>(false);
    const [statusPay, setStatusPay] = useState<boolean>(false);
    const [errorGetQr, setErrorGetQr] = useState<boolean>(false);
    const [decodedUrl, setDecodedUrl] = useState('');
    const canvasRef = useRef(null);

    useEffect(() => {
        if (dataPay && dataPay.qrImage) {
            // Создаем изображение из base64
            const img = new Image();
            img.src = dataPay.qrImage;

            img.onload = () => {
                const canvas: any = canvasRef.current;
                const context = canvas.getContext('2d');

                // Устанавливаем размеры канваса
                canvas.width = img.width;
                canvas.height = img.height;

                // Рисуем изображение на канвасе
                context.drawImage(img, 0, 0);

                // Получаем данные изображения из канваса
                const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

                const code = jsQR(imageData.data, canvas.width, canvas.height);

                if (code) {
                    setDecodedUrl(code.data); // Сохраняем декодированную ссылку
                }
            };
        }

    }, [dataPay?.qrImage]);


    useEffect(() => {
        if (seconds > 0) {
            const timerId = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);

            // Очищаем таймер при размонтировании компонента или изменении состояния
            return () => clearInterval(timerId);
        } else {
            setDataPay(null);
            setDecodedUrl('');
        }
    }, [seconds]);

    useEffect(() => {
        getPayOff();

        const fetchData = async () => {
            try {
                if (isOpen && activeItemBlackListPay && !statusPay) {
                    profileContext.getDebtStatus(activeItemBlackListPay?.paymentId)
                        .then((res) => {
                            if (res.data.isPaid) {
                                setStatusPay(res.data.isPaid)
                                setTimeout(() => {
                                    closeModalBlackList()
                                }, 3000);
                            }
                        })
                        .catch((e) => console.log(e))
                }
            } catch (error) {
                console.error('Ошибка при выполнении запроса:', error);
            }
        };

        const intervalId = setInterval(() => {
            fetchData();
        }, 4000);

        return () => clearInterval(intervalId);
    }, [])

    const getPayOff = () => {
        if (activeItemBlackListPay) {
            setLoadButton(true)
            profileContext.getDebtPayOff(activeItemBlackListPay.paymentId)
                .then((res) => {
                    setDataPay(res.data);
                    setSeconds(res.data.secondsToExpiration);
                    setErrorGetQr(false);
                })
                .catch(() => {
                    setErrorGetQr(true)
                    setDataPay(null);
                    setDecodedUrl('');
                })
                .finally(() => setLoadButton(false));
        }
    }

    const closeModalBlackList = () => {
        setDataPay(null)
        setActiveItemBlackListPay(null)
        closeModal(false)
    }


    return (
        <Modal
            open={isOpen}
            onClose={closeModalBlackList}
            className={styles.modalContainer}
            windowProps={{
                className: styles.connectionModal + " custom_theme",
            }}
        >
            <IconButton
                className={styles.modalCloseBtn}
                aria-label="Закрыть модальное окно"
                icon={CloseIcon}
                onClick={() => closeModal(false)}
                size="s"
            />

            {
                statusPay ?
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <img
                            className={styles.identMB_m}
                            src={success}
                            width={"80px"}
                            alt={"успешное подключение"}
                        />
                        <Typography
                            className={styles.identMB_m}
                            align={"center"}
                            variant={"heading-xl"}
                        >
                            Оплата прошла успешно
                        </Typography>
                    </div>
                    :
                    <>
                        <Typography variant={'heading-xl'} align={'left'}>
                            Оплатить задолженность
                        </Typography>
                        <Typography variant={'text-m'} align={'left'}>
                            Отсканируйте QR-код или нажмите на кнопку "Оплатить задолженность"
                        </Typography>
                        <div className={styles.qrWrapper}>
                            {
                                dataPay ?
                                    <img width={'100%'} height={'100%'} src={dataPay.qrImage} alt="QR код на оплату"/>
                                    :
                                    <img src={noQr} alt="нет qr кода"/>
                            }
                        </div>

                        <div className={styles.btnWrapper}>
                            {decodedUrl ? (
                                    <Button
                                        fullWidth={true}
                                        // style={{margin: '0 auto'}}
                                        variant={"contained"}
                                        disabled={!decodedUrl}
                                        onClick={() => {
                                            window.open(decodedUrl, "_blank");
                                        }}
                                    >
                                        Оплатить задолженность
                                    </Button>
                                ) :
                                <Button
                                    fullWidth={true}
                                    loading={loadButton}
                                    // style={{margin: '0 auto'}}
                                    variant={"contained"}
                                    disabled={seconds > 0}
                                    onClick={() => {
                                        getPayOff()
                                    }}
                                >
                                    Обновить QR для оплаты
                                </Button>
                            }
                        </div>
                        <div style={{justifyContent: 'center'}} className={styles.btnWrapper}>
                            {
                                errorGetQr ?
                                    <>
                                        <Typography align={'center'} color="disabled">Не удалось получить QR на оплату,
                                            обратитесь за помощью по адресу {' '}
                                            <a
                                                href="mailto:support@face2.ru"> support@face2.ru </a>
                                        </Typography>
                                    </>
                                    :
                                    <>
                                        {
                                            seconds <= 0 ?
                                                <>
                                                    <Typography align={'center'} color="disabled">Запросите новый
                                                        QR</Typography>
                                                    <br/>
                                                    <br/>
                                                </>

                                                :
                                                <Typography align={'center'} color="disabled">Время до истечения QR кода
                                                    - <br/>{seconds} секунд</Typography>
                                        }
                                    </>
                            }
                        </div>


                        <canvas ref={canvasRef} style={{display: 'none'}}/>
                        <Divider/>
                        {
                            activeItemBlackListPay &&
                            <div className={styles.modal__title}>
                                <img src={metroIcon} alt="Иконка места оплаты"/>
                                <div style={{textAlign: "left"}}>
                                    <Typography align="left">{activeItemBlackListPay?.text}</Typography>
                                    <Typography variant="text-l_strong" align="left">
                                        {activeItemBlackListPay?.amount / 100} ₽
                                    </Typography>
                                    <Typography
                                        color={'disabled'}
                                        style={{marginBottom: "2px"}}
                                        variant="text-s"
                                    >
                                        {getDateTime(activeItemBlackListPay?.time)}
                                    </Typography>
                                </div>
                            </div>
                        }
                    </>
            }
        </Modal>
    );
};

export default BlackListPayModal;
