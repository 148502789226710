import {Typography} from "@ornament-ui/kit/__inner__/esm/components/Typography";
import styles from "./header.module.scss";
import {useEffect, useState} from "react";
import {store} from "../../../store/store";
import {pages} from "../../utils/constants";
import {Page} from "../../../types";
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";

const NavBar = observer(() => {
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState<Page['value']>('profile'); // Инициализация начальным значением

    useEffect(() => {
        const path = window.location.pathname.replace('/', '');

        if (path.includes('profile')) {
            setActiveItem('profile');
        } else if (path.includes('object')) {
            setActiveItem('object');
        } else if (path.includes('history')) {
            setActiveItem('history');
        } else if (path.includes('help')) {
            setActiveItem('help');
        } else {
            setActiveItem('profile'); // Или другое значение по умолчанию
        }
    }, [window.location.pathname]);

    const handleNavItemClick = (page: Page) => {
        store.setPage(page);
        setActiveItem(page.value)
        navigate(page.value)
    };

    return (
        <nav className={styles.nav}>
            <ul className={styles.nav__list}>
                {pages.map((item, index: number) => (
                    <li
                        key={index}
                        onClick={() => handleNavItemClick(item)}
                        className={activeItem === item.value ? styles.active : ""}
                    >
                        <Typography className={styles.nav__itemMenu}>
                            {item.icon}
                            {item.name}
                        </Typography>
                    </li>
                ))}
            </ul>
            <div
                style={{
                    transform: `translateY(${
                        pages.findIndex((page) => page.value === activeItem) * 44
                    }px)`,
                }}
                className={styles.borderActiveMenu}
            />
        </nav>
    );
});

export default NavBar;
