import { Skeleton } from "@ornament-ui/kit/Skeleton";
import styles from "./skeletonHystory.module.scss";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";

const SkeletonHistory = () => {
  const isMobile = useIsMobile();

  return (
    <li className={styles.cardItem}>
      <div>
        <Skeleton
          className={styles.skeleton__icon}
          variant={"rectangular"}
          width={isMobile ? 42 : 42}
          height={isMobile ? 42 : 42}
        />
      </div>
      <div className={styles.skeleton__info}>
        <div>
          {/* Название */}
          <Skeleton
            className={styles.skeleton__tag}
            variant={"rectangular"}
            width={60}
          />
          <Skeleton
            className={styles.skeleton__adress}
            variant={"rectangular"}
            width={200}
          />
        </div>
        <Skeleton
          className={styles.skeleton__tag}
          variant={"rectangular"}
          width={40}
        />
      </div>
    </li>
  );
};

export default SkeletonHistory;
