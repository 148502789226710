import { Modal } from "@ornament-ui/kit/__inner__/esm/components/Modal";
import styles from "./BioTypeModal.module.scss";
import { Typography } from "@ornament-ui/kit/__inner__/esm/components/Typography";
import { typeBioModalText } from "../../../../constant";
import { IconButton } from "@ornament-ui/kit/__inner__/esm/components/IconButton";
import { CloseIcon } from "@ornament-ui/icons";
import { typeBioModalObj, Vectors } from "../../../../types";
import { toJS } from "mobx";
import { store } from "../../../../store/store";
import { useEffect, useState } from "react";
import { getDate } from "../../../../services/profileContext";

type Props = {
  isOpen: boolean;
  closeModal: (arg: boolean) => void;
  type: Vectors["displayedBioType"];
};

const BioTypeModal = ({ isOpen, closeModal, type = "Упрощенная" }: Props) => {
  const [typeToObj, setTypeToObj] = useState<typeBioModalObj>(getType());
  const [dataType, setDataType] = useState<
    Vectors["expirationDate"] | undefined
  >(undefined);

  useEffect(() => {
    if (store.profile && isOpen) {
      setTypeToObj(getType());
      const date = toJS(store?.profile?.vectors)?.find(
        (vector) => vector.displayedBioType === type
      )?.expirationDate;
      if (date) {
        setDataType(getDate(date));
      } else {
        setDataType(undefined)
      }
    }
  }, [isOpen]);

  function getType() {
    switch (type) {
      case "Упрощенная":
        return "light";
      case "Стандартная":
        return "standart";
      case "Подтвержденная":
        return "confirmed";
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => closeModal(false)}
      className={styles.modalContainer}
      windowProps={{
        className: styles.connectionModal + " custom_theme",
      }}
    >
      <IconButton
        className={styles.modalCloseBtn}
        aria-label="Закрыть модальное окно"
        icon={CloseIcon}
        onClick={() => closeModal(false)}
        size="s"
      />
      <Typography
        className="MixSpacing MixSpacing_mb_4x"
        variant="text-xl_strong"
      >
        {typeBioModalText[typeToObj].title}
      </Typography>
      <div className={styles.info}>
        <div className={styles.info__item}>
          <Typography color="disabled">Срок действия</Typography>
          <Typography align="right">{dataType ? dataType : ""}</Typography>
        </div>
        <div className={styles.info__item}>
          <Typography color="disabled">Перечень услуг</Typography>
          <Typography align="right">
            {typeBioModalText[typeToObj].servicesList}
          </Typography>
        </div>
        <div className={styles.info__item}>
          <Typography color="disabled">Место регистрации</Typography>
          <Typography align="right">
            {typeBioModalText[typeToObj].registration}
          </Typography>
        </div>
      </div>
      <Typography variant="text-l_strong">
        {typeBioModalText[typeToObj].subTitle}
      </Typography>
      <ul className={styles.options}>
        {typeBioModalText[typeToObj].options.map((item, index) => {
          return <li key={index}>{item}</li>;
        })}
      </ul>
    </Modal>
  );
};

export default BioTypeModal;
