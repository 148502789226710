import React, { useState } from "react";
import { Button } from "@ornament-ui/kit/Button";
import { IconButton } from "@ornament-ui/kit/IconButton";
import { Modal } from "@ornament-ui/kit/Modal";
import { Typography } from "@ornament-ui/kit/Typography";
import { CloseIcon } from "@ornament-ui/icons";
import styles from "./conntectionModal.module.scss";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";
import profileContext from "../../../../services/profileContext";
import { ConnectionModalType } from "../../../../types";
import success from "./assets/success.png";
import error from "./assets/error.png";

export enum ModalType {
  connect,
  disconnect,
}

export enum ModalResponseStatus {
  notSend,
  ok,
  notOk,
}

const ConnectionModal: React.FC<ConnectionModalType> = ({
  type,
  setConnectionDataCandidate,
  onConnected,
  connectionDataCandidate,
}) => {
  const [loading, setLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState(
    ModalResponseStatus.notSend
  );
  const isMobile = useIsMobile();

  const changeInstanceHandler = async () => {
    setLoading(true);
    try {
      const response = await profileContext.putInstance({
        bioInstanceId: connectionDataCandidate.id,
        enable: type === ModalType.connect,
      });

      if (response.status === 200) {
        setResponseStatus(ModalResponseStatus.ok);
      }
    } catch (e) {
      setResponseStatus(ModalResponseStatus.notOk);
    }
    setLoading(false);
    onConnected();
  };

  return (
    <Modal
      onClose={() => {
        setConnectionDataCandidate((prev) => ({
          ...prev,
          isOpen: false,
        }));
      }}
      open={connectionDataCandidate.isOpen}
      windowProps={{
        className: styles.connectionModal + " custom_theme",
      }}
      className={styles.modalContainer}
      onExited={() => setResponseStatus(ModalResponseStatus.notSend)}
    >
      <IconButton
        className={styles.modalCloseBtn}
        aria-label="Закрыть модальное окно"
        icon={CloseIcon}
        onClick={() =>
          setConnectionDataCandidate((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }
        size="s"
      />

      {responseStatus === ModalResponseStatus.notSend ? (
        <>
          <Typography
            className={styles.connectionModalTitle}
            variant={"heading-xl"}
          >
            Подтвердите&nbsp;
            {type === ModalType.connect && "подключение"}
            {type === ModalType.disconnect && "отключение"}
            <br />
            био&shy;ме&shy;трии
          </Typography>

          <div className={styles.connectionModalAddressContainer}>
            <Typography
              variant={"text-l_strong"}
              className={styles.connectionModalCompanyTitle}
            >
              {connectionDataCandidate?.name}
            </Typography>
            <Typography variant={isMobile ? "text-s" : "text-m"}>
              {connectionDataCandidate?.address}
            </Typography>
          </div>
          <div
            className={styles.connectionModalLawInfo}
            dangerouslySetInnerHTML={{
              __html: `${connectionDataCandidate.description}`,
            }}
          />

          <div className={styles.connectionModalButtons}>
            <Button
              size={isMobile ? "xs" : "s"}
              variant={"ghost"}
              style={{
                fontSize: "16px",
                background: "#F0F5FF",
              }}
              onClick={() =>
                setConnectionDataCandidate((prev) => ({
                  ...prev,
                  isOpen: false,
                }))
              }
            >
              Отмена
            </Button>
            <Button
              size={isMobile ? "xs" : "s"}
              style={{
                fontSize: "16px",
                background: "#893EEC",
              }}
              loading={loading}
              onClick={changeInstanceHandler}
            >
              Подтвердить
            </Button>
          </div>
        </>
      ) : responseStatus === ModalResponseStatus.ok ? (
        <div className={styles.successContainer}>
          <img
            className={styles.identMB_m}
            src={success}
            width={"80px"}
            alt={"успешное подключение"}
          />
          <Typography
            className={styles.identMB_m}
            align={"center"}
            variant={"heading-xl"}
          >
           {type === ModalType.connect && "Запрос на подключение биометрии отправлен"}
            {type === ModalType.disconnect && "Биометрия отключена"}

          </Typography>
          <Typography align="center">
            Биометрия будет {type === ModalType.connect && "подключена, когда организация подтвердит подключение"}
            {type === ModalType.disconnect && "отключена"}
          </Typography>

          <div className={styles.address}>
            <Typography
              className={styles.identMB_s}
              variant={isMobile ? "text-s_strong" : "text-m_strong"}
            >
              {connectionDataCandidate.name}
            </Typography>
            <Typography
              variant={isMobile ? "text-s" : "text-m"}
              color={"secondary"}
            >
              {connectionDataCandidate.address}
            </Typography>
          </div>
        </div>
      ) : (
        <div className={styles.successContainer}>
          <img
            className={styles.identMB_m}
            width={"80px"}
            src={error}
            alt={"ошибка"}
          />
          <Typography
            className={styles.identMB_m}
            align={"center"}
            variant={"heading-xl"}
          >
            Не удалось отправить запрос
          </Typography>
          <Typography
            className={styles.identMB_s}
            align={"center"}
          >
            Попробуйте повторить попытку позднее
          </Typography>
        </div>
      )}
    </Modal>
  );
};
export default ConnectionModal;
