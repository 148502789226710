import { Typography } from "@ornament-ui/kit/Typography";
import styles from "./typeBio.module.scss";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { store } from "../../../../store/store";
import { Vectors } from "../../../../types";
import { toJS } from "mobx";
import TypeBioComponent from "./TypeBioComponent";
import { getDate } from "../../../../services/profileContext";

type TypeBioProps = {
  setIsOpenBioTypeModal: (arg0: boolean) => void;
  setTypeBioModal: (arg0: Vectors['displayedBioType']) => void;
};

const TypeBio = observer(
  ({ setIsOpenBioTypeModal, setTypeBioModal }: TypeBioProps) => {
    const [data, setData] = useState<Vectors[] | null>(null);

    useEffect(() => {
      if (store.profile) {


        setData(
          toJS(store.profile.vectors).map((vector) => {
            return {
              displayedBioType: vector.displayedBioType,
              expirationDate: getDate(vector.expirationDate),
            };
          })
        );
      }
    }, [store.getProfile]);

    return (
      <div className={styles.typeBio}>
        <Typography variant="heading-xl" style={{ marginBottom: "15px" }}>
          Типы биометрии
        </Typography>
        <div className={styles.typeBio__wrp}>
          <TypeBioComponent
            data={data}
            type={"Упрощенная"}
            setIsOpenBioTypeModal={setIsOpenBioTypeModal}
            setTypeBioModal={setTypeBioModal}
          />

          <TypeBioComponent
            data={data}
            type={"Стандартная"}
            setIsOpenBioTypeModal={setIsOpenBioTypeModal}
            setTypeBioModal={setTypeBioModal}
          />

          <TypeBioComponent
            data={data}
            type={"Подтвержденная"}
            setIsOpenBioTypeModal={setIsOpenBioTypeModal}
            setTypeBioModal={setTypeBioModal}
          />
        </div>
      </div>
    );
  }
);

export default TypeBio;
