import {Typography} from "@ornament-ui/kit/__inner__/esm/components/Typography";
import {ChevronLeftIcon} from "@ornament-ui/icons";
import {useNavigate} from "react-router-dom";


const SelectedHelp = () => {
    const navigate = useNavigate();
  return (
    <>
      <Typography onClick={() => navigate(-1)} color="action" style={{display: 'flex', cursor: 'pointer'}}>
        <ChevronLeftIcon />В раздел "Помощь"
      </Typography>
    </>
  );
};

export default SelectedHelp;
