import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {isAuthenticated, reAuthenticate} from "../services/profileContext";

export function Redirect({ to }: { to: string }) {
    let navigate = useNavigate();
    useEffect(() => {
        navigate(to);
    });
    return null;
}

type PrivateRouteProps = {
    children: React.ReactNode;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
    const [isAuth, setIsAuth] = useState(isAuthenticated());
    const [isRedirect, setIsRedirect] = useState(false);

    useEffect(() => {
        async function tryAuth() {
            if (!isAuth) {
                try {
                    await reAuthenticate();

                    if (isAuthenticated()) {
                        setIsAuth(true);
                    } else {
                        setIsRedirect(true);
                    }
                } catch (e) {
                    setIsRedirect(true);
                }
            }
        }
        tryAuth();
    }, [isAuth, isRedirect]);

    return (
        <>
            {isAuth && children}
            {isRedirect && <Redirect to="/login" />}
        </>
    );
};

export default PrivateRoute;
