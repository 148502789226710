import {IconButton} from "@ornament-ui/kit/__inner__/esm/components/IconButton";
import {Modal} from "@ornament-ui/kit/__inner__/esm/components/Modal";
import {Typography} from "@ornament-ui/kit/__inner__/esm/components/Typography";
import styles from "../historyComponents.module.scss";
import {CloseIcon} from "@ornament-ui/icons";
import {DebtPaymentsDto, Transaction} from "../../../../../types";
import {getDateTime} from "../../../../../services/profileContext";
import metroIcon from "./assets/seccess.svg";

type EventModalProps = {
  isOpen: boolean;
  closeModal: (arg: boolean) => void;
  event: Transaction | DebtPaymentsDto;
};

const EventModal = ({ isOpen, closeModal, event }: EventModalProps) => {

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        closeModal(false)
      }}
      className={styles.modalContainer}
      windowProps={{
        className: styles.connectionModal + " custom_theme",
      }}
    >
      <IconButton
        className={styles.modalCloseBtn}
        aria-label="Закрыть модальное окно"
        icon={CloseIcon}
        onClick={() => closeModal(false)}
        size="s"
      />
      <div className={styles.modal__title}>
        <img src={metroIcon} alt="Иконка места оплаты" />
        <div style={{ textAlign: "left" }}>
          <Typography align="left">{event.text}</Typography>
          <Typography variant="text-l_strong" align="left">
            -{event.amount / 100}₽
          </Typography>
        </div>
      </div>
      <Typography variant="text-l_strong">Подробности</Typography>
      <div className={styles.info}>
        <div className={styles.info__item}>
          <Typography color="disabled">Дата списания</Typography>
          <Typography align="right">{getDateTime(event.time)}</Typography>
        </div>
        <div className={styles.info__item}>
          <Typography color="disabled">Тип оплаты</Typography>
          <Typography align="right">Система быстрых платежей</Typography>
        </div>
      </div>
    </Modal>
  );
};

export default EventModal;
