import { Pages } from '../../types/index';
import { UserIcon } from "@ornament-ui/icons";
import { QrCodeIcon } from "@ornament-ui/icons";
import { TimeIcon } from "@ornament-ui/icons";
import { QuestionCircleOutlineIcon } from '@ornament-ui/icons';

export const pages: Pages = [
  { name: "Профиль", value: "profile", icon: <UserIcon size="s" /> },
  { name: "Объекты", value: "object", icon: <QrCodeIcon size="s" /> },
  { name: "История платежей", value: "history", icon: <TimeIcon size="s" /> },
  { name: "Помощь", value: "help", icon: <QuestionCircleOutlineIcon size="s" /> },
]
