import { Typography } from "@ornament-ui/kit/__inner__/esm/components/Typography";
import styles from "./successTitle.module.scss"

type Props = {
  text: string;
  color?: string;
  icon: any

}

const SuccessTitle = ({ text = "", color = '#04C300', icon = null }: Props) => {


  return (
    <Typography
      className={styles.status}
      style={{
        color: color,
      }}
      variant="text-m"
    >
      {icon && icon}
      {text}
    </Typography>
  );
};

export default SuccessTitle;
