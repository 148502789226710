import React from "react";
import styles from "./help.module.scss";
import {Outlet} from "react-router-dom";

export type HelpItemType = null | "sbp";

const HelpComponents = () => {

    return (
        <div className={styles.cardListContainer}>
            <section className={styles.wrapper}>
                <Outlet/>
            </section>
        </div>

    );
};

export default HelpComponents;
