import { Typography } from "@ornament-ui/kit/__inner__/esm/components/Typography";
import styles from "./typeBio.module.scss";
import { IconButton } from "@ornament-ui/kit/__inner__/esm/components/IconButton";
import { Skeleton } from "@ornament-ui/kit/__inner__/esm/components/Skeleton";
import SuccessTitle from "../SuccessTitle";
import { TickIcon } from "@ornament-ui/icons";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";
import { QuestionCircleOutlineIcon } from "@ornament-ui/icons";
import { Vectors } from "../../../../types";

type TypeBioComponentProps = {
  data: Vectors[] | null;
  type: Vectors["displayedBioType"];
  setIsOpenBioTypeModal: (arg0: boolean) => void;
  setTypeBioModal: (arg0: Vectors["displayedBioType"]) => void;
};

const TypeBioComponent = ({
  data,
  type,
  setIsOpenBioTypeModal,
  setTypeBioModal,
}: TypeBioComponentProps) => {
  const isMobile = useIsMobile();

  const handleOpenModalTypeBio = (type: Vectors["displayedBioType"]) => {
    setTypeBioModal(type);
    setIsOpenBioTypeModal(true);
  };

  return (
    <>
      <div className={styles.typeBio__block}>
        {data ? (
          <>
            <div className={styles.typeBio__block_info}>
              <>
                <Typography variant="text-m_strong">{type}</Typography>
                <IconButton
                  compressed={true}
                  color="secondary"
                  icon={QuestionCircleOutlineIcon}
                  size="m"
                  variant="ghost"
                  onClick={() => handleOpenModalTypeBio(type)}
                />
              </>
            </div>
              {data.find((vector: Vectors) => vector.displayedBioType === type) ? (
                  <SuccessTitle
                      text={"Действует до " + data.find((vector: Vectors) => vector.displayedBioType === type)?.expirationDate}
                      icon={<TickIcon size="m" color="#04C300" />}
                      color={"#04C300"}
                  />
              ) : (
                  <Typography
                      style={{
                          color: "#893EEC",
                          cursor: "pointer",
                      }}
                      variant="text-m"
                      onClick={() => {
                          window.open("https://ebs.ru/citizens/#how_register", "_blank");
                      }}
                  >
                      Как зарегистрировать
                  </Typography>
              )}
          </>
        ) : (
          <div className={styles.typeBio__block_info}>
            <Skeleton
              className={styles.skeleton__title}
              variant={"rectangular"}
              width={isMobile ? 97 : 97}
              height={isMobile ? 12 : 12}
            />
            <Skeleton
              className={styles.skeleton__icon}
              variant={"rectangular"}
              width={isMobile ? 20 : 20}
              height={isMobile ? 20 : 20}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TypeBioComponent;
