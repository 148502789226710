import {Button} from "@ornament-ui/kit/Button";
import {Container} from "@ornament-ui/kit/Container";
import {Typography} from "@ornament-ui/kit/Typography";
import GosUslugiXsIcon from "./assets/GosUslugiXsIcon";
import styles from "./loginPage.module.scss";
import {useIsMobile} from "../utils/hooks/useIsMobile";
import {isLoginAlredy} from "../../services/profileContext";
import {Redirect} from "../PrivateRoute";
import {Loader} from "@ornament-ui/kit/Loader";
import {Backdrop} from "@ornament-ui/kit/Backdrop";
import {useEffect} from "react";
import {qParams, showPreload} from "../../initialState";
import woman from './assets/woman.png';


const LoginPage = () => {
    const isMobile = useIsMobile();
    const isAuth = isLoginAlredy();
    useEffect(() => {
        if (showPreload) {
            const timer = setTimeout(
                () => (window.location.href = linkToGosUslugi),
                3000
            );
            return () => {
                clearTimeout(timer);
            };
        }
    }, []);
    if (isAuth) {
        return <Redirect to="/profile"/>;
    }

    const linkToGosUslugi =
        process.env.NODE_ENV === "development"
            ? `${process.env.REACT_APP_BACKEND_HOST}/auth/esia${qParams}`
            : `/auth/esia${qParams}`;

    return (
        <div className={styles.login}>
            <Container size={"l"} className={styles.container}>

                <div className={styles.login__card}>
                    <Typography variant={"heading-2xl"} className={styles.heading_xl}>
                        Вход в Face2
                    </Typography>
                    <Typography
                        variant={isMobile ? "text-s" : "text-m"}
                        className={styles.text_m}
                    >
                        Для получения услуг по биометрии войдите через портал Госуслуги
                    </Typography>
                    <a href={linkToGosUslugi}>
                        <Button
                            variant={"contained"}

                            fullWidth={true}
                            iconLeft={GosUslugiXsIcon}
                            className={styles.btn}
                        >
                            Войти через Госуслуги
                        </Button>
                    </a>
                </div>

                <Typography
                    className={styles.authorBG}
                    variant={"text-s"}
                    color={"ghostDisabled"}
                >
                    Автор фотографии: Aiony Haust
                </Typography>
                <img className={styles.woman} src={woman} alt="woman"/>
            </Container>
            <Backdrop
                onClose={function noRefCheck() {
                }}
                zIndex={5}
                open={showPreload}
            >
                <Loader color="var(--color-content-primary-inverse)" size="l"/>
            </Backdrop>
        </div>
    );
};

export default LoginPage;
