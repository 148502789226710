import React, {useContext, useState} from 'react';
import styles from "../../main.module.scss";
import {Typography} from "@ornament-ui/kit/Typography";
import {Input} from "@ornament-ui/kit/Input";
import {IconButton} from "@ornament-ui/kit/IconButton";
import SkeletonCard from "../SkeletonCard/SkeletonCard";
import PermissionCard from "../PermissionCard/PermissionCard";
import {OutletContextType, OutletUseContext} from "../../MainPage";
import {CloseIcon, SearchIcon} from "@ornament-ui/icons";

const Objects = () => {
    const {
        setConnectionDataCandidate,
        isLoadingInstances,
        instancesAll
    } = useContext<OutletContextType>(OutletUseContext);
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };


    const filteredInstancesAll = instancesAll?.filter((instance) =>
        instance?.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <Typography
                className={styles.permissions__title}
                variant={"heading-2xl"}
            >
                {/* instancesAll */}
                Обьекты для подключения
            </Typography>
            {!!instancesAll?.length && (
                <Input
                    label="Поиск по объектам"
                    placeholder="Найти"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    renderLeft={SearchIcon}
                    renderRight={({size}) => (
                        <IconButton
                            variant="function"
                            size={size}
                            icon={CloseIcon}
                            onClick={() => {
                                setSearchQuery("");
                            }}
                        />
                    )}
                    size="s"
                    className="searchObjectConnect"
                    style={{
                        minWidth: 250,
                        borderRadius: "20px",
                        margin: "20px 0",
                        overflow: "hidden",
                    }}
                />
            )}

            <div className={styles.cardListContainer}>
                <ul className={styles.cardList}>
                    {isLoadingInstances ? (
                        <>
                            <SkeletonCard/>
                            <SkeletonCard/>
                            <SkeletonCard/>
                            <SkeletonCard/>
                            <SkeletonCard/>
                        </>
                    ) : filteredInstancesAll && !!filteredInstancesAll?.length ? (
                        filteredInstancesAll.map((data) => {
                            return (
                                <PermissionCard
                                    key={data.id}
                                    data={data}
                                    setConnectionDataCandidate={setConnectionDataCandidate}
                                />
                            );
                        })
                    ) : (
                        <Typography
                            className={styles.notFilterFoundText}
                            variant={"heading-xl"}
                            color={"tertiary"}
                        >
                            Пока нет доступных объектов
                        </Typography>
                    )}
                </ul>
            </div>
        </>
    );
};

export default Objects;