import React, {Context, createContext, useEffect, useState} from "react";
import {Container} from "@ornament-ui/kit/Container";
import styles from "./main.module.scss";
import ConnectionModal from "./components/ConnectionModal/ConnectionModal";
import ProfileContext from "../../services/profileContext";
import {store} from "../../store/store";
import {observer} from "mobx-react";
import MoreModal from "./components/MoreModal/MoreModal";
import SomethingWrong from "../errors/SomethingWrong/SomethingWrong";
import BiometricsModal from "./components/BiometricsModal/BiometricsModal";
import {initBiometricsData, initConnectionDataCandidate, initMoreData,} from "../../initialState";
import {BioInstanceDto} from "../../types";
import AgreementError from "./components/AgreementError/AgreementError";
import {Outlet, useLocation, useNavigate} from "react-router-dom";

// Определите тип OutletContextType
export type OutletContextType = {
    setConnectionDataCandidate: (data: any) => void; // Замените any на конкретный тип
    instancesConnected: BioInstanceDto[];
    instancesRequests: BioInstanceDto[];
    instancesAll: BioInstanceDto[];
    isLoadingInstances: boolean,

};

export const OutletUseContext: Context<OutletContextType> = createContext<OutletContextType>({
    setConnectionDataCandidate: () => {
    },
    instancesConnected: [],
    instancesRequests: [],
    instancesAll: [],
    isLoadingInstances: false,
});

const Index = observer(() => {
    const navigate = useNavigate();
    const location = useLocation();
    if (location.pathname === "/") {
        navigate('/profile')
    }
    const [isLoadingInstances, setIsLoadingInstances] = useState<boolean>(false);
    const [loadingError, setLoadingError] = useState<boolean>(false);

    const [instancesRequests, setInstancesRequests] = useState<
        BioInstanceDto[]
    >([]);
    const [instancesConnected, setInstancesConnected] = useState<
        BioInstanceDto[]
    >([]);
    const [instancesAll, setInstancesConnectedAll] = useState<
        BioInstanceDto[]
    >([]);
    const [connectionDataCandidate, setConnectionDataCandidate] = useState(
        initConnectionDataCandidate
    );
    const [moreData, setMoreData] = useState(initMoreData);


    useEffect(() => {
        if (!store.profile) {
            fetchProfile();
        }
        fetchInstances();
    }, []);


    const fetchProfile = async () => {
        try {
            // setIsLoadingInstances(true);
            const {data} = await ProfileContext.getProfile();
            //TODO чек полей (проверка ДТО)
            store.setProfile(data);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchInstances = async () => {
        setIsLoadingInstances(true);
        try {
            const {data} = await ProfileContext.getInstances();
            store.setInstances(data);

            const dataRequests: BioInstanceDto[] = [];
            const dataConnected: BioInstanceDto[] = [];
            const dataAll: BioInstanceDto[] = [];

            data.forEach((element) => {
                if (element.rejected) {
                    dataRequests.push(element);
                } else if (element.enabled) {
                    dataConnected.push(element);
                } else {
                    dataAll.push(element);
                }
            });
            setInstancesRequests(dataRequests);
            setInstancesConnected(dataConnected);
            setInstancesConnectedAll(dataAll);
        } catch (e) {
            setLoadingError(true);
        } finally {
            setIsLoadingInstances(false);
        }
    };

    const onConnectedHandler = () => {
        fetchInstances();
    };

    if (loadingError) {
        return <SomethingWrong/>;
    }

    return (
        <div style={{overflowY: "auto", width: "100%"}}>
            {store.profile && !store.profile.isAgreement && <AgreementError/>}
            <Container size={"l"} className={styles.container}>
                <section className={styles.permissions}>
                    <OutletUseContext.Provider
                        value={{
                            setConnectionDataCandidate,
                            instancesConnected,
                            instancesRequests,
                            isLoadingInstances,
                            instancesAll
                        }}>
                        <Outlet/>
                    </OutletUseContext.Provider>
                </section>
            </Container>

            <MoreModal
                moreData={moreData}
                setMoreData={setMoreData}
                setConnectionDataCandidate={setConnectionDataCandidate}
            />
            <ConnectionModal
                type={connectionDataCandidate.type}
                setConnectionDataCandidate={setConnectionDataCandidate}
                onConnected={onConnectedHandler}
                connectionDataCandidate={connectionDataCandidate}
            />
        </div>
    );
});

export default Index;
