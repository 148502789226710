export const typeBioModalText = {
  light: {
    title: "Упрощенная биометрия",
    servicesList: "Ограничен",
    registration: "Приложение или импорт из организаций",
    subTitle: "Пользователи с упрощённой биометрией могут:",
    options: [
      "Оплачивать проезд в транспорте",
      "Проходить на территорию офисов и для других простых сервисов",
      "Оплачивать товары и услуги на сумму до 2 500 ₽",
    ],
  },
  standart: {
    title: "Стандартная биометрия",
    servicesList: "Ограничен",
    registration: "Приложение или импорт из организаций",
    subTitle: "Пользователи со стандартной  биометрией могут:",
    options: [
      "Безопасно авторизоваться на Госуслугах",
      "Оплачивать товары и услуги на сумму до 5 000 ₽",
      "Дистанционно заключать договоры на оказание услуг связи",
      "Дистанционно оформить карту болельщика",
      "Дистанционно сдать промежуточную и итоговую аттестации в вузах",
      "Оплачивать проезд в транспорте",
    ],
  },
  confirmed: {
    title: "Подтверждённая биометрия",
    servicesList: "Не ограничен",
    registration: "Центр обслуживания",
    subTitle: "Пользователи с подтвержденной биометрией могут:",
    options: [
      "Получить доступ ко всем услугам по биометрии",
      "Заменить паспорт",
      "Дистанционно получить банковские услуги",
      "Оформить усиленную квалифицированную электронную подпись"
    ],
  },
};
