import React, {useEffect, useState} from 'react';
import {Modal} from "@ornament-ui/kit/Modal";
import styles from "./moreModal.module.scss";
import {Button} from "@ornament-ui/kit/Button";
import {ModalType} from "../ConnectionModal/ConnectionModal";
import {CallIcon, CloseIcon, InfoCircleOutlineIcon, MainIcon} from "@ornament-ui/icons";
import {useIsMobile} from "../../../utils/hooks/useIsMobile";
import {Typography} from "@ornament-ui/kit/Typography";
import {Collapse} from "@ornament-ui/kit/Collapse";
import userIcon from "../PermissionCard/assets/userIcon.svg";
import {IconButton} from "@ornament-ui/kit/IconButton";
import {MoreModalType} from "../../../../types";

const MoreModal: React.FC<MoreModalType> = ({
                                                moreData,
                                                setMoreData,
                                                setConnectionDataCandidate
                                            }) => {
    const isMobile = useIsMobile();
    const [expanded] = useState(false);
    const [data, setData] = useState(moreData);

    useEffect(() => {
        setData(moreData)
    }, [moreData])
    return (
        <Modal
            open={moreData.isOpen}
            className={styles.modalContainer}
            windowProps={{
                className: styles.connectionModal + " custom_theme",
            }}
            onClose={() => {
                setMoreData((prev) => ({
                    ...prev,
                    isOpen: false,
                }))
            }}
        >
            <div className={styles.cardItem}>
                <div className={styles.imageContainer}>
                    <img
                        src={`data:image/png;base64,${data.photoBase64}`}
                        alt="лого компании"
                        style={{maxHeight: "50px"}}
                    />
                </div>
                <IconButton
                    className={styles.modalCloseBtn}
                    aria-label="Закрыть модальное окно"
                    icon={CloseIcon}
                    onClick={() =>
                        setMoreData((prev) => ({
                            ...prev,
                            isOpen: false,
                        }))
                    }
                    size="s"
                />
                <Typography
                    variant={isMobile ? "text-l_strong" : "text-xl_strong"}
                    style={{height: '100%', maxHeight: '65px', display: 'flex', alignItems: 'center'}}
                >{data.name}</Typography>

                <div className={styles.contacts}>
                    <ul className={styles.contacts__list}>
                        <li className={styles.contacts__item}>
                            <div>
                                <MainIcon size={"s"}/>
                            </div>
                            <Typography variant={isMobile ? "text-s" : "text-m"}>{data.address}</Typography>
                        </li>
                        <li className={styles.contacts__item}>
                            <Collapse expanded={expanded} collapsedSize={'auto'}>
                                <div className={styles.contacts__grid}>
                                    <CallIcon size={"s"}/>
                                    {data?.phones ? data.phones.map((callNumber, index) => {
                                            return (
                                                <Typography
                                                    key={index}
                                                    className={styles.contacts__gridItem}
                                                    variant={isMobile ? "text-s" : "text-m"}
                                                >
                                                    {callNumber}
                                                </Typography>
                                            );
                                        })
                                        :
                                        null
                                    }
                                </div>
                            </Collapse>
                        </li>
                        <li className={styles.contacts__item}>
                            <img src={userIcon} alt={"иконка пользователя"}/>
                            <Typography variant={"text-m"}>{data.useFor}</Typography>
                        </li>
                        <li className={styles.contacts__item}>
                            <div>
                                <InfoCircleOutlineIcon size={"s"}/>
                            </div>
                            <Typography style={{overflowY: 'scroll'}}
                                        variant={"text-m"}>{data?.description}</Typography>
                        </li>
                    </ul>
                    <div className={styles.connectionModalButtons}>
                        <Button
                            size={isMobile ? "xs" : "s"}
                            variant={"outlined"}
                            onClick={() =>
                                setMoreData(() => ({
                                    ...data,
                                    isOpen: false,
                                }))
                            }
                        >
                            Отмена
                        </Button>
                        {data.enabled ? (
                            <Button
                                variant={"outlined"}
                                size={isMobile ? "xs" : "s"}
                                onClick={() =>
                                    setConnectionDataCandidate({
                                        ...data,
                                        type: ModalType.disconnect,
                                        isOpen: true,
                                    })
                                }
                            >
                                Отключить
                            </Button>
                        ) : (
                            <Button
                                size={isMobile ? "xs" : "s"}
                                style={{
                                    marginTop: 'auto'
                                }}
                                onClick={() => {
                                    setMoreData(() => ({
                                        ...data,
                                        isOpen: false,
                                    }))
                                    setConnectionDataCandidate({
                                        ...moreData,
                                        type: ModalType.connect,
                                        isOpen: true,
                                    })
                                }
                                }
                            >
                                Подключить биометрию
                            </Button>
                        )}
                    </div>

                </div>
            </div>

        </Modal>
    );
};

export default MoreModal;